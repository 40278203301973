import { Fragment } from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'dibs-react-intl';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import {
    getPreviouslyAppliedCategory,
    getBackToCategory,
    getCurrentCategory,
    shouldShowBackToAllCategories,
} from '../../../utils/categoryHelpers';
import { updateUriRef } from '../../../actions/filterActions';

// components
import { SbRespRefineMenuLinkList } from '../SbRespRefineMenuLinkList/SbRespRefineMenuLinkList';
import { SbSharedRefineMenuLink } from '../../SbSharedRefineMenu/SbSharedRefineMenuLink/SbSharedRefineMenuLink';

export const SbRespRefineMenuFilterCategoryComponent = props => {
    const { itemSearch, filters, shouldUseExpandableList } = props;
    const { appliedFilters, totalResults, pageType, isAttributePage } = itemSearch;
    const previouslyAppliedCategory = getPreviouslyAppliedCategory(appliedFilters);
    const backToCategory = getBackToCategory(appliedFilters);
    const currentCategory = getCurrentCategory(appliedFilters, filters);
    const showBackToAllCategories = shouldShowBackToAllCategories(
        { pageType, isAttributePage },
        filters
    );

    const { name, values } = currentCategory;
    const shouldIndent = !!backToCategory || (!backToCategory && showBackToAllCategories);

    const { linkReference, linkable } = get(previouslyAppliedCategory, 'values[0]') || {};
    const backToCategoryValue = get(backToCategory, 'values[0].displayName');

    const sharedMenuLinkProps = {
        onClick: e => {
            e.preventDefault();
            props.onClick(previouslyAppliedCategory.name, previouslyAppliedCategory.values[0], e);
        },
        linkReference,
        linkable,
    };

    if (previouslyAppliedCategory) {
        return (
            <Fragment>
                {/* Back to all categories link - only shown on non-Browse pages */}
                {!backToCategory && showBackToAllCategories && (
                    <SbSharedRefineMenuLink
                        {...sharedMenuLinkProps}
                        text={
                            <FormattedMessage
                                id="sb.SbRespRefineMenuFilterCategory.backToAll"
                                defaultMessage="Back To All Categories"
                            />
                        }
                        dataTn="back-to-all"
                    />
                )}
                {/* Back to category Link */}
                {!!backToCategory && (
                    <SbSharedRefineMenuLink
                        {...sharedMenuLinkProps}
                        text={
                            <FormattedMessage
                                id="sb.SbRespRefineMenuFilterCategory.back"
                                defaultMessage="Back To {backToCategory}"
                                values={{
                                    backToCategory: backToCategoryValue,
                                }}
                            />
                        }
                        dataTn={`back-to-${backToCategoryValue}`}
                    />
                )}
                {/* current category */}
                <SbSharedRefineMenuLink
                    selected
                    count={totalResults}
                    shouldIndent={shouldIndent}
                    onClick={e => e.preventDefault()}
                    text={get(previouslyAppliedCategory, 'values.[0].displayName')}
                    linkReference={linkReference}
                />
                {/* filter options */}
                {previouslyAppliedCategory.name !== currentCategory.name && (
                    <SbRespRefineMenuLinkList
                        values={values}
                        filterName={name}
                        shouldIndent={shouldIndent}
                        shouldUseExpandableList={shouldUseExpandableList}
                    />
                )}
            </Fragment>
        );
    } else {
        return (
            <Fragment>
                <SbSharedRefineMenuLink
                    text={
                        <FormattedMessage
                            id="sb.SbRespRefineMenuFilterCategory.all"
                            defaultMessage="All"
                        />
                    }
                    selected
                    linkReference="/search"
                    count={totalResults}
                    dataTn="all-categories"
                    onClick={e => e.preventDefault()}
                />
                <SbRespRefineMenuLinkList values={values} filterName={name} />
            </Fragment>
        );
    }
};

SbRespRefineMenuFilterCategoryComponent.defaultProps = {
    shouldUseExpandableList: true,
};

SbRespRefineMenuFilterCategoryComponent.propTypes = {
    filters: PropTypes.array.isRequired,
    itemSearch: PropTypes.object.isRequired,
    shouldUseExpandableList: PropTypes.bool,
};

function mapDispatchToProps(dispatch) {
    return {
        onClick(filterName, filterValue, event) {
            dispatch(updateUriRef({ filterName, filterValue, isDeselected: true, event }));
        },
    };
}

export const SbRespRefineMenuFilterCategory = connect(
    null,
    mapDispatchToProps
)(
    createFragmentContainer(SbRespRefineMenuFilterCategoryComponent, {
        itemSearch: graphql`
            fragment SbRespRefineMenuFilterCategory_itemSearch on ItemSearchQueryConnection {
                pageType
                totalResults
                isAttributePage
                appliedFilters {
                    name
                    localizedFilterName
                    canBeDismissed
                    values {
                        urlLabel
                        linkReference
                        count
                        displayName
                    }
                }
            }
        `,
        filters: graphql`
            fragment SbRespRefineMenuFilterCategory_filters on SearchBrowseFilter
            @relay(plural: true) {
                name
                values {
                    count
                    displayName
                    linkReference
                    urlLabel
                    linkable
                }
            }
        `,
    })
);
