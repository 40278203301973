/**
 * @generated SignedSource<<26730b186e01aeae53dc66384052ab5a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbSharedRefineMenuFilterArtOrientation_itemSearch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuMultiSelectList_itemSearch">;
  readonly " $fragmentType": "SbSharedRefineMenuFilterArtOrientation_itemSearch";
};
export type SbSharedRefineMenuFilterArtOrientation_itemSearch$key = {
  readonly " $data"?: SbSharedRefineMenuFilterArtOrientation_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuFilterArtOrientation_itemSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbSharedRefineMenuFilterArtOrientation_itemSearch",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuMultiSelectList_itemSearch"
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "505b3f5b904af9d3ae24116f8321dc2c";

export default node;
