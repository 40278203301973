import { createFragmentContainer, graphql } from 'react-relay/legacy';

// components
import { SbSharedRefineMenuCaratWeight } from '../../SbSharedRefineMenu/SbSharedRefineMenuCaratWeight/SbSharedRefineMenuCaratWeight';

export const SbRespRefineMenuFilterCaratWeightComponent = props => (
    <SbSharedRefineMenuCaratWeight {...props} />
);

export const SbRespRefineMenuFilterCaratWeight = createFragmentContainer(
    SbRespRefineMenuFilterCaratWeightComponent,
    {
        itemSearch: graphql`
            fragment SbRespRefineMenuFilterCaratWeight_itemSearch on ItemSearchQueryConnection {
                ...SbSharedRefineMenuCaratWeight_itemSearch
            }
        `,
    }
);
