import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import slugify from 'dibs-slugify/exports/slugify';

// components
import { FormattedNumber } from 'dibs-react-intl';
import { AbsoluteAnchor } from '../../../global/AbsoluteAnchor/AbsoluteAnchor';
import { Link } from 'dibs-elements/exports/Link';

//styles
import styles from './SbSharedRefineMenuLink.scss';

export function SbSharedRefineMenuLink(props) {
    const {
        text,
        count,
        onClick,
        linkReference,
        linkable,
        className,
        shouldIndent,
        dataTn,
        selected,
        isHidden,
    } = props;
    const refetchInFlight = useSelector(state => state.filters.refetchInFlight);

    const containerClass = classnames(styles.container, className, {
        [styles.selected]: selected,
        [styles.indent]: shouldIndent,
        'is-hidden': isHidden,
        [styles.disabled]: refetchInFlight,
    });

    const dataTnText = slugify(dataTn || text);

    return (
        <Link
            buyerLinkType="linkNoDecoration"
            onClick={onClick}
            className={containerClass}
            dataTn={`facet-link${selected ? '-selected' : ''}-${dataTnText}${
                refetchInFlight ? '-disabled' : ''
            }`}
        >
            <AbsoluteAnchor
                className={styles.text}
                dataTn={`facet-content-${dataTnText}`}
                linkReference={linkReference}
                linkable={linkable}
                inert
            >
                {text}
            </AbsoluteAnchor>
            {!!count && (
                <span className={styles.count} data-tn={`facet-count-${dataTnText}`}>
                    <FormattedNumber value={count} />
                </span>
            )}
        </Link>
    );
}

SbSharedRefineMenuLink.propTypes = {
    count: PropTypes.number,
    selected: PropTypes.bool,
    dataTn: PropTypes.string,
    linkable: PropTypes.bool,
    linkReference: PropTypes.string,
    className: PropTypes.string,
    shouldIndent: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    isHidden: PropTypes.bool,
};
