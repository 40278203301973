/**
 * @generated SignedSource<<f132d30ba6a43a313a46b935b5c3604f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbSharedRefineMenuPriceRanges_itemSearch$data = {
  readonly appliedFilters: ReadonlyArray<{
    readonly canBeDismissed: boolean | null;
    readonly name: string | null;
    readonly values: ReadonlyArray<{
      readonly linkReference: string | null;
      readonly urlLabel: string | null;
    } | null> | null;
  } | null> | null;
  readonly displayUriRef: string | null;
  readonly filters: ReadonlyArray<{
    readonly name: string | null;
    readonly values: ReadonlyArray<{
      readonly currency: string | null;
      readonly displayName: string | null;
      readonly linkReference: string | null;
      readonly linkable: boolean | null;
      readonly urlLabel: string | null;
    } | null> | null;
  } | null> | null;
  readonly " $fragmentType": "SbSharedRefineMenuPriceRanges_itemSearch";
};
export type SbSharedRefineMenuPriceRanges_itemSearch$key = {
  readonly " $data"?: SbSharedRefineMenuPriceRanges_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuPriceRanges_itemSearch">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "urlLabel",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "linkReference",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbSharedRefineMenuPriceRanges_itemSearch",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayUriRef",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SearchBrowseParsedParam",
      "kind": "LinkedField",
      "name": "appliedFilters",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "canBeDismissed",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SearchBrowseParsedParamValue",
          "kind": "LinkedField",
          "name": "values",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            (v2/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SearchBrowseFilter",
      "kind": "LinkedField",
      "name": "filters",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "SearchBrowseFilterValue",
          "kind": "LinkedField",
          "name": "values",
          "plural": true,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "linkable",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "displayName",
              "storageKey": null
            },
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "currency",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};
})();

(node as any).hash = "0f880cd7244017a60a0bee3a45ca0016";

export default node;
