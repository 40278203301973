import { defineMessages } from 'dibs-react-intl';

export const abbreviations = defineMessages({
    IN: {
        id: 'abf.measurementUnits.abbreviations.inches',
        defaultMessage: 'IN',
    },
    CM: {
        id: 'abf.measurementUnits.abbreviations.centimeters',
        defaultMessage: 'CM',
    },
});

export const IN = 'IN';
export const CM = 'CM';

export const MEASUREMENT_UNITS = [IN, CM];

export const LOCAL_STORAGE_KEY = 'userMeasurementUnit';
export const DISPLAY_MEASUREMENTS_LOCAL_STORAGE_KEY = 'display-measurements';
