import { createFragmentContainer, graphql } from 'react-relay/legacy';

// components
import { SbSharedRefineMenuMultiSelectSearch } from '../SbSharedRefineMenuMultiSelect/SbSharedRefineMenuMultiSelectSearch';

const ITEM_COUNT = 20;

export const SbSharedRefineMenuFilterArtSubjectComponent = props => (
    <SbSharedRefineMenuMultiSelectSearch collapsedItemCount={ITEM_COUNT} {...props} />
);

export const SbSharedRefineMenuFilterArtSubject = createFragmentContainer(
    SbSharedRefineMenuFilterArtSubjectComponent,
    {
        viewer: graphql`
            fragment SbSharedRefineMenuFilterArtSubject_viewer on Viewer {
                ...SbSharedRefineMenuMultiSelectSearch_viewer
            }
        `,
        itemSearch: graphql`
            fragment SbSharedRefineMenuFilterArtSubject_itemSearch on ItemSearchQueryConnection {
                ...SbSharedRefineMenuMultiSelectSearch_itemSearch
            }
        `,
    }
);
