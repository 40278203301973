import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { getFilterValues } from '../../SbSharedRefineMenu/sbSharedRefineMenuHelpers';

//components
import { SbRespRefineMenuStoneCutSelect } from '../SbRespRefineMenuStoneCutSelect/SbRespRefineMenuStoneCutSelect';
import { SbSharedRefineMenuTypeaheadContainer } from '../../SbSharedRefineMenu/SbSharedRefineMenuTypeahead/SbSharedRefineMenuTypeaheadContainer';

export const SbRespRefineMenuFilterStoneCutComponent = props => {
    const { values, filterName, itemSearch, isClient, viewer } = props;

    const appliedFilterValues = getFilterValues(itemSearch?.appliedFilters, filterName);

    const [stoneCutMap, setStoneCutMap] = useState({});
    const [loadStoneCutMapInitialized, setLoadStoneCutMapInitialized] = useState(false);

    useEffect(() => {
        if (isClient && !loadStoneCutMapInitialized) {
            setLoadStoneCutMapInitialized(true);
            import(
                /* webpackChunkName: "sbRespRefineMenuStoneCutComponentMap" */
                './sbRespRefineMenuStoneCutComponentMap'
            ).then(moduleExport => {
                setStoneCutMap(moduleExport.stoneCutMap);
            });
        }
    }, [isClient, loadStoneCutMapInitialized]);

    return (
        <SbSharedRefineMenuTypeaheadContainer
            appliedFilterValues={appliedFilterValues}
            filterName={filterName}
            availableFilterValues={values}
            viewer={viewer}
            itemSearch={itemSearch}
            iconMap={stoneCutMap}
        >
            {({ valuesToShow }) => (
                <SbRespRefineMenuStoneCutSelect
                    filterName={filterName}
                    itemSearch={itemSearch}
                    valuesToShow={valuesToShow}
                    stoneCutMap={stoneCutMap}
                />
            )}
        </SbSharedRefineMenuTypeaheadContainer>
    );
};

SbRespRefineMenuFilterStoneCutComponent.propTypes = {
    isClient: PropTypes.bool,
    values: PropTypes.array.isRequired,
    filterName: PropTypes.string.isRequired,
    itemSearch: PropTypes.object.isRequired,
    viewer: PropTypes.object.isRequired,
};

export const SbRespRefineMenuFilterStoneCut = createFragmentContainer(
    SbRespRefineMenuFilterStoneCutComponent,
    {
        viewer: graphql`
            fragment SbRespRefineMenuFilterStoneCut_viewer on Viewer {
                ...SbSharedRefineMenuTypeaheadContainer_viewer
            }
        `,
        itemSearch: graphql`
            fragment SbRespRefineMenuFilterStoneCut_itemSearch on ItemSearchQueryConnection {
                ...SbRespRefineMenuStoneCutSelect_itemSearch
                ...SbSharedRefineMenuTypeaheadContainer_itemSearch
                appliedFilters {
                    name
                    localizedFilterName
                    canBeDismissed
                    clearAllLinkReference
                    values {
                        urlLabel
                        linkReference
                        count
                        displayName
                    }
                }
            }
        `,
    }
);
