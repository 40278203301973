/**
 * @generated SignedSource<<c2a1fd50c9a0328ae66eb5fc0a5045cb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbSharedRefineMenuFilterOrigin_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuMultiSelectSearch_viewer">;
  readonly " $fragmentType": "SbSharedRefineMenuFilterOrigin_viewer";
};
export type SbSharedRefineMenuFilterOrigin_viewer$key = {
  readonly " $data"?: SbSharedRefineMenuFilterOrigin_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuFilterOrigin_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbSharedRefineMenuFilterOrigin_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuMultiSelectSearch_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "14194ad7a0d4d805cf2e7d5c2687e616";

export default node;
