import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'dibs-react-intl';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import get from 'lodash.get';

import { hasPriceFilterApplied } from '../sbSharedRefineMenuHelpers';
import { CURRENCY } from '../../SbSharedRefineMenu/sbSharedRefineMenuConstants';

// components
import { SbSharedRefineMenuInputRange } from './SbSharedRefineMenuInputRange';

const { labelTo, labelFrom } = defineMessages({
    labelTo: {
        id: 'sb.SbSharedRefineMenuFilterPrice.maximum',
        defaultMessage: '{currency} max',
    },
    labelFrom: {
        id: 'sb.SbSharedRefineMenuFilterPrice.minimum',
        defaultMessage: '{currency} min',
    },
});

export const SbSharedRefineMenuInputRangeCurrencyComponent = props => {
    const { size, currency, applyOnBlur, itemSearch, showInvalidRangeErrorMessage } = props;
    const intl = useIntl();
    // The price values should be prefilled if they are
    // coming from appliedFilters
    const appliedFilters = get(itemSearch, 'appliedFilters');
    const showAnimatedPlaceholder = !hasPriceFilterApplied(appliedFilters);

    return (
        <SbSharedRefineMenuInputRange
            {...props}
            size={size}
            labelTo={intl.formatMessage(labelTo, { currency })}
            statKey={currency}
            additionalFilter={CURRENCY}
            labelFrom={intl.formatMessage(labelFrom, { currency })}
            applyOnBlur={applyOnBlur}
            showInvalidRangeErrorMessage={showInvalidRangeErrorMessage}
            showAnimatedPlaceholder={showAnimatedPlaceholder}
            trackCustomRange
        />
    );
};

SbSharedRefineMenuInputRangeCurrencyComponent.propTypes = {
    applyOnBlur: PropTypes.bool,
    currency: PropTypes.string.isRequired,
    itemSearch: PropTypes.object.isRequired,
    showInvalidRangeErrorMessage: PropTypes.bool,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
};

export const SbSharedRefineMenuInputRangeCurrency = createFragmentContainer(
    SbSharedRefineMenuInputRangeCurrencyComponent,
    {
        itemSearch: graphql`
            fragment SbSharedRefineMenuInputRangeCurrency_itemSearch on ItemSearchQueryConnection {
                ...SbSharedRefineMenuInputRange_itemSearch
                appliedFilters {
                    name
                }
            }
        `,
    }
);
