import { useIntl, defineMessages } from 'dibs-react-intl';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Input } from 'dibs-elements/exports/Input';
import { Link } from 'dibs-elements/exports/Link';
import SearchIcon from 'dibs-icons/exports/legacy/MagnifyingGlass';

import styles from './SbSharedRefineMenuSearchInput.scss';

const messages = defineMessages({
    buttonLabel: {
        id: 'sb.SbSharedRefineMenuSearchInput.buttonLabel',
        defaultMessage: 'Search',
    },
    formLabel: {
        id: 'sb.SbSharedRefineMenuSearchInput.formLabel',
        defaultMessage: `{filterName, select,
        creator {Creator}
        location {Locations}
        material {Materials}
        metal {Metals}
        origin {Places}
        stone {Stones}
        stonecut {Stone Cuts}
        other {}
    }`,
    },
});

export function SbSharedRefineMenuSearchInput(props) {
    const {
        autoComplete,
        className,
        horizontalSpacing,
        onInput,
        onSubmit,
        placeholder,
        size,
        value,
        ariaControls,
        filterName = '',
        onBlur,
        onFocus,
    } = props;
    const intl = useIntl();
    const containerClass = classnames(className);
    return (
        <div className={containerClass}>
            <form
                onSubmit={onSubmit}
                role="search"
                aria-label={intl.formatMessage(messages.formLabel, {
                    filterName: filterName.replace('-', ''),
                })}
                action=""
            >
                <Input
                    ariaControls={ariaControls}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    type="search"
                    autoComplete={autoComplete}
                    autoCorrect={false}
                    dataTn="search-input"
                    horizontalSpacing={horizontalSpacing}
                    onChange={onInput}
                    placeholder={placeholder}
                    size={size}
                    spellCheck={false}
                    value={value}
                    rightDecorator={
                        <Link
                            className={styles.searchIconWrapper}
                            ariaLabel={intl.formatMessage(messages.buttonLabel)}
                            onClick={onSubmit}
                        >
                            <SearchIcon />
                        </Link>
                    }
                />
            </form>
        </div>
    );
}

SbSharedRefineMenuSearchInput.defaultProps = {
    autoComplete: 'off',
    horizontalSpacing: 'medium',
    size: 'medium',
};

SbSharedRefineMenuSearchInput.propTypes = {
    autoComplete: PropTypes.string,
    className: PropTypes.string,
    horizontalSpacing: PropTypes.oneOf(['small', 'medium']),
    onInput: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired,
    size: PropTypes.oneOf(['medium', 'large']),
    value: PropTypes.string.isRequired,
    ariaControls: PropTypes.string,
    filterName: PropTypes.string,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
};
