import { FC } from 'react';
import { graphql, useFragment } from 'react-relay';
import { CurrencyProvider } from 'dibs-buyer-layout/exports/CurrencyProvider';

// components
import { SbSharedRefineMenuRingSize } from '../SbSharedRefineMenuRingSize/SbSharedRefineMenuRingSize';

import { SbSharedRefineMenuFilterRingSize_itemSearch$key } from './__generated__/SbSharedRefineMenuFilterRingSize_itemSearch.graphql';
import { SbSharedRefineMenuFilterRingSize_filters$key } from './__generated__/SbSharedRefineMenuFilterRingSize_filters.graphql';

const filtersFragment = graphql`
    fragment SbSharedRefineMenuFilterRingSize_filters on SearchBrowseFilter @relay(plural: true) {
        ...SbSharedRefineMenuRingSize_filters
    }
`;

const itemSearchFragment = graphql`
    fragment SbSharedRefineMenuFilterRingSize_itemSearch on ItemSearchQueryConnection {
        ...SbSharedRefineMenuRingSize_itemSearch
    }
`;

type Props = {
    filters: SbSharedRefineMenuFilterRingSize_filters$key;
    itemSearch: SbSharedRefineMenuFilterRingSize_itemSearch$key;
};

export const SbSharedRefineMenuFilterRingSize: FC<Props> = ({
    filters: filtersRef,
    itemSearch: itemSearchRef,
}) => {
    const filters = useFragment(filtersFragment, filtersRef);

    const itemSearch = useFragment(itemSearchFragment, itemSearchRef);

    return (
        <CurrencyProvider
            render={({ currency }: { currency: string }) => (
                <SbSharedRefineMenuRingSize
                    currency={currency}
                    filters={filters}
                    itemSearch={itemSearch}
                />
            )}
        />
    );
};
