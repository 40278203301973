import { createFragmentContainer, graphql } from 'react-relay/legacy';

// components
import { SbSharedRefineMenuMultiSelectList } from '../SbSharedRefineMenuMultiSelect/SbSharedRefineMenuMultiSelectList';

export const SbSharedRefineMenuFilterStyleComponent = props => (
    <SbSharedRefineMenuMultiSelectList {...props} />
);

export const SbSharedRefineMenuFilterStyle = createFragmentContainer(
    SbSharedRefineMenuFilterStyleComponent,
    {
        itemSearch: graphql`
            fragment SbSharedRefineMenuFilterStyle_itemSearch on ItemSearchQueryConnection {
                ...SbSharedRefineMenuMultiSelectList_itemSearch
            }
        `,
    }
);
