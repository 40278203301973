import { graphql, useFragment } from 'react-relay';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Radio } from 'dibs-elements/exports/Radio';
import { FormattedMessage } from 'dibs-react-intl';
import { localStorage } from 'dibs-browser-storage';

import { updateRingMeasurementType } from '../../../actions/ringMeasurementTypeActions';
import { useRingMeasurementType } from '../../hooks/ringSize/useRingMeasurementType';
import { createNewFilterUriRef } from '../../../utils/uriUtils';
import { updateUriRef } from '../../../actions/filterActions';

import styles from './SbSharedRefineMenuRingSize.scss';
import {
    LOCAL_STORAGE_KEY,
    RING_SIZE_MM,
    RING_MEASUREMENT_TYPE_PARAM,
    RING_SIZE_US,
} from '../../../constants/ringSize';

export const SbSharedRefineMenuRingSizeUnitSelector = ({ currency, itemSearch: itemSearchRef }) => {
    const itemSearch = useFragment(
        graphql`
            fragment SbSharedRefineMenuRingSizeUnitSelector_itemSearch on ItemSearchQueryConnection {
                ...useRingMeasurementType_itemSearch
            }
        `,
        itemSearchRef
    );

    const generatedUriRef = useSelector(state => state?.filters?.generatedUriRef);
    const dispatch = useDispatch();
    const [ringMeasurementType] = useRingMeasurementType({ currency, itemSearch });

    const saveRingSizeUnitPreference = (_ringMeasurementType, callback) => {
        localStorage.setItem(LOCAL_STORAGE_KEY, _ringMeasurementType);
        callback();
    };

    const onChangeRingSizeUnit = (newRingMeasurementType, event) => {
        saveRingSizeUnitPreference(newRingMeasurementType, () => {
            const filterName = RING_MEASUREMENT_TYPE_PARAM;
            const filterValue = {
                urlLabel: newRingMeasurementType,
            };
            const uriRef = createNewFilterUriRef(
                generatedUriRef,
                filterName,
                newRingMeasurementType
            );
            dispatch(updateUriRef({ filterName, filterValue, uriRef, event }));

            dispatch(updateRingMeasurementType(newRingMeasurementType));
        });
    };

    return (
        <div className={styles.unitSelectorContainer}>
            <Radio
                radioClass={styles.unitSelector}
                checked={ringMeasurementType === RING_SIZE_US}
                label={
                    <FormattedMessage
                        id="sb.SbSharedRefineMenuRingSize.unitSelectorLabel.us"
                        defaultMessage="US Size"
                    />
                }
                labelClass={styles.unitSelectorLabel}
                name="ring-measurement-type-select"
                dataTn="ring-measurement-type-select-us"
                key="ring-measurement-type-select-us"
                onChange={(value, e) => {
                    onChangeRingSizeUnit(value, e);
                }}
                value="US"
            />
            <Radio
                radioClass={styles.unitSelector}
                checked={ringMeasurementType === RING_SIZE_MM}
                label={
                    <FormattedMessage
                        id="sb.SbSharedRefineMenuRingSize.unitSelectorLabel.mm"
                        defaultMessage="Circumference (mm)"
                    />
                }
                labelClass={styles.unitSelectorLabel}
                name="ring-measurement-type-select"
                dataTn="ring-measurement-type-select-mm"
                key="ring-measurement-type-select-mm"
                onChange={(value, e) => {
                    onChangeRingSizeUnit(value, e);
                }}
                value="MM"
            />
        </div>
    );
};

SbSharedRefineMenuRingSizeUnitSelector.propTypes = {
    currency: PropTypes.string,
    itemSearch: PropTypes.object.isRequired,
};
