import { createFragmentContainer, graphql } from 'react-relay/legacy';

// components
import { SbSharedRefineMenuRadioSelectList } from '../SbSharedRefineMenuRadioSelect/SbSharedRefineMenuRadioSelectList';

export const SbSharedRefineMenuFilterItemTypeComponent = props => (
    <SbSharedRefineMenuRadioSelectList {...props} />
);

export const SbSharedRefineMenuFilterItemType = createFragmentContainer(
    SbSharedRefineMenuFilterItemTypeComponent,
    {
        itemSearch: graphql`
            fragment SbSharedRefineMenuFilterItemType_itemSearch on ItemSearchQueryConnection {
                ...SbSharedRefineMenuRadioSelectList_itemSearch
            }
        `,
    }
);
