import { Component } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'dibs-elements/exports/Input';

/**
 * This was copied from :
 * https://github.com/1stdibs/dibs-components/blob/72601bcb5ec81114749e05daf8903cc1e4ada89f/packages/dibs-currency-input/src/CurrencyInput.jsx
 */
class SbSharedLabeledInput extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.inputNode = null;

        this.state = {
            value: this.normalizeInput(props.value || ''),
            cursorIndex: 0,
            // state to show/hide defaulting value when the input placeholder feature could be skipped
            showDefaultedValue: !props.showAnimatedPlaceholder,
        };
    }

    componentDidUpdate(prevProps) {
        const node = this.inputNode;
        if (node && document && document.activeElement === node) {
            const { number: formattedNumber } = this.formatInput(this.state.value);
            node.value = formattedNumber === '0' ? '' : formattedNumber;
            node.selectionStart = node.selectionEnd = this.state.cursorIndex;
        }
        const newState = {};
        if (prevProps.value !== this.props.value) {
            newState.value = this.normalizeInput(this.props.value || '');
        }
        if (prevProps.showAnimatedPlaceholder !== this.props.showAnimatedPlaceholder) {
            newState.showDefaultedValue = !this.props.showAnimatedPlaceholder;
        }

        if (Object.keys(newState).length > 0) {
            this.setState(newState);
        }
    }

    normalizeInput(incomingValue = '') {
        const { allowDecimal } = this.props;

        const regexValue = String(incomingValue).match(/^\d*\.?\d*/);
        const value = regexValue ? regexValue[0] : '';

        if (!value) {
            return '';
        } else if (allowDecimal) {
            return parseFloat(value).toFixed(2);
        } else {
            return parseFloat(value).toFixed(0);
        }
    }

    formatIntlInput(value = '') {
        const { currency, allowDecimal } = this.props;

        // use resolvedOptions to figure out the appropriate number of fraction digits
        // for the passed currency
        const resolvedOptions = new Intl.NumberFormat(currency, {
            style: 'currency',
            currency,
        }).resolvedOptions();

        // format without {style: currency}
        const number = new Intl.NumberFormat(currency, {
            minimumFractionDigits: allowDecimal ? resolvedOptions.minimumFractionDigits : 0,
        }).format(value);

        return { number };
    }

    formatInput(value = '') {
        const { currency, locale } = this.props;
        if (!value) {
            return { number: '' };
        } else if (locale || currency) {
            return this.formatIntlInput(value);
        } else {
            return { number: value };
        }
    }

    getRawNumbersAsString(str = '') {
        const parsedString = str.replace(/[^\d]/g, '');
        if (parsedString) {
            if (this.props.allowDecimal) {
                return (parseInt(parsedString) / 100).toFixed(2);
            }
            return parseInt(parsedString).toString();
        }
        return parsedString;
    }

    handleBlur(newValue, e) {
        const rawValue = this.getRawNumbersAsString(newValue);
        if (this.props.onBlur) {
            this.props.onBlur(rawValue, e);
        }
    }

    handleChange(e) {
        this.inputNode = e.target;

        const { number: formattedStateValue } = this.formatInput(this.state.value);

        let newValue = e.target.value;
        let index = e.target.selectionStart;

        // Handle backspace on "," or "."
        if (formattedStateValue && newValue.length < formattedStateValue.length) {
            if (!(parseInt(formattedStateValue[index]) >= 0) && newValue.length > 1) {
                newValue = newValue.slice(0, index - 1) + newValue.slice(index);
                index = index - 2;
                if (index < 0) {
                    index = 0;
                }
            }
        }
        const rawValue = this.getRawNumbersAsString(newValue);

        if (this.props.onChange) {
            this.props.onChange(rawValue);
        }

        // If showAnimatedPlaceholder is true then use case is possible
        // where the formattedStateValue and newValue would be equal
        // (price value and availableMin would be same when single digit value is entered)
        // so the showDefaultedValue check is needed to cover use case
        if (formattedStateValue !== newValue || !this.state.showDefaultedValue) {
            const { number: formattedInputValue } = this.formatInput(rawValue);
            const distanceFromEnd = newValue.length - index;
            let nextIndex = formattedInputValue.length - distanceFromEnd;

            if (nextIndex < 0) {
                nextIndex = 0;
            }

            this.setState(
                {
                    value: rawValue,
                    cursorIndex: nextIndex,
                    showDefaultedValue: true,
                },
                () => {
                    if (formattedInputValue === '0' && formattedStateValue === '0') {
                        this.forceUpdate();
                    }
                }
            );
        }
    }

    render() {
        const { label, dataTn, disabled, hasError, name, size, ariaLabel, ariaDescribedBy } =
            this.props;
        const { value, showDefaultedValue } = this.state;
        const { number: formattedValue } = this.formatInput(value || '');
        const defaultValue = showDefaultedValue ? formattedValue : '';

        return (
            <Input
                ariaLabel={ariaLabel}
                ariaDescribedBy={ariaDescribedBy}
                hasAnimatedPlaceholder
                dataTn={dataTn}
                disabled={disabled}
                hasError={hasError}
                horizontalSpacing="small"
                maxLength={17}
                name={name}
                placeholder={label}
                onBlur={e => this.handleBlur(defaultValue, e)}
                onChange={this.handleChange}
                showIcons={false}
                size={size}
                value={defaultValue}
            />
        );
    }
}

SbSharedLabeledInput.defaultProps = {
    onFocus: () => {},
    disabled: false,
    hasError: false,
    size: 'large',
    showAnimatedPlaceholder: false,
};

SbSharedLabeledInput.propTypes = {
    label: PropTypes.node,
    allowDecimal: PropTypes.bool,
    currency: PropTypes.string,
    dataTn: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    hasError: PropTypes.bool,
    horizontalSpacing: PropTypes.string,
    locale: PropTypes.string,
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    value: PropTypes.string,
    showAnimatedPlaceholder: PropTypes.bool,
    ariaDescribedBy: PropTypes.string,
    ariaLabel: PropTypes.string,
};

export { SbSharedLabeledInput };
