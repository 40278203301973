import { FormattedMessage } from 'dibs-react-intl';
import PropTypes from 'prop-types';
import { Link } from 'dibs-elements/exports/Link';

import styles from './SbSharedRefineMenuTypeaheadControls.scss';

export function SbSharedRefineMenuTypeaheadControls(props) {
    const { showSearch, showClear, filterName, categoryL1 } = props;
    const creator = (
        <FormattedMessage
            id="sb.SbMobileRefineMenu.typeahead.controls.filter.creator"
            defaultMessage={`{categoryL1, select,
            art {Artists}
            fashion {Designers}
            other {Creators}
        }`}
            values={{ categoryL1 }}
        />
    );
    const filter = (
        <FormattedMessage
            id="sb.SbMobileRefineMenu.typeahead.controls.filter"
            defaultMessage={`{filterName, select,
            creator {{creator}}
            location {Locations}
            material {Materials}
            metal {Metals}
            origin {Places}
            stone {Stones}
            stonecut {Stone Cuts}
            other {}
        }`}
            values={{ filterName: filterName.replace('-', ''), creator }}
        />
    );

    return (
        <div className={styles.container}>
            <Link buyerLinkType="standardLink" onClick={props.onToggleClick} dataTn="search">
                <FormattedMessage
                    id="sb.SbMobileRefineMenu.typeahead.controls.toggle"
                    defaultMessage={`{showSearch, select,
                        true {Search {filter}}
                        other {Cancel}
                    }`}
                    values={{ showSearch, filter }}
                />
            </Link>
            {showClear && (
                <Link
                    buyerLinkType="standardLink"
                    className={styles.clear}
                    onClick={props.onClearClick}
                    dataTn="search-clear"
                >
                    <FormattedMessage
                        id="sb.SbMobileRefineMenu.typeahead.controls.clear"
                        defaultMessage="Clear"
                    />
                </Link>
            )}
        </div>
    );
}

SbSharedRefineMenuTypeaheadControls.propTypes = {
    filterName: PropTypes.string.isRequired,
    categoryL1: PropTypes.string,
    onToggleClick: PropTypes.func.isRequired,
    onClearClick: PropTypes.func,
    showSearch: PropTypes.bool.isRequired,
    showClear: PropTypes.bool,
};
