import { FC } from 'react';
import { useFragment, graphql } from 'react-relay';
import { SbSharedRefineMenuFilterFraming_itemSearch$key } from './__generated__/SbSharedRefineMenuFilterFraming_itemSearch.graphql';

import { defineMessages } from 'dibs-react-intl';
import {
    FRAMING_OPTION_AVAILABLE,
    FRAMING_STATUS,
} from '../../SbSharedRefineMenu/sbSharedRefineMenuConstants';

// components
import { SbSharedRefineMenuFramingInput } from '../SbSharedRefineMenuFramingInput/SbSharedRefineMenuFramingInput';

const messages = defineMessages({
    frameIncludedTitle: {
        id: 'sb.SbSharedRefineMenuFilterFrameIncluded.title',
        defaultMessage: 'Frame Included',
    },
    framingOptionsAvailableTitle: {
        id: 'sb.SbSharedRefineMenuFilterFramingOptionAvailable.title',
        defaultMessage: 'Framing Options Available',
    },
});

type Props = {
    itemSearch: SbSharedRefineMenuFilterFraming_itemSearch$key;
};

export const SbSharedRefineMenuFilterFraming: FC<Props> = ({ itemSearch: itemSearchRef }) => {
    const itemSearch = useFragment(
        graphql`
            fragment SbSharedRefineMenuFilterFraming_itemSearch on ItemSearchQueryConnection {
                ...SbSharedRefineMenuFramingInput_itemSearch
            }
        `,
        itemSearchRef
    );

    return (
        <>
            <SbSharedRefineMenuFramingInput
                itemSearch={itemSearch}
                filterName={FRAMING_STATUS}
                title={messages.frameIncludedTitle}
            />
            <SbSharedRefineMenuFramingInput
                itemSearch={itemSearch}
                filterName={FRAMING_OPTION_AVAILABLE}
                title={messages.framingOptionsAvailableTitle}
            />
        </>
    );
};
