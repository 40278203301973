import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateUriRef } from '../../../actions/filterActions';

// components
import { SbSharedRefineMenuInputCheckbox } from '../SbSharedRefineMenuInput/SbSharedRefineMenuInputCheckbox';

const SbSharedRefineMenuMultiSelectComponent = props => {
    const { appliedFilterValues, valuesToShow, onChange } = props;

    return (
        <>
            {valuesToShow.map((item, index) => {
                const isChecked = appliedFilterValues.some(
                    appliedValue => appliedValue.urlLabel === item.urlLabel
                );
                return (
                    <SbSharedRefineMenuInputCheckbox
                        key={`${item.urlLabel}-${index}`}
                        value={item.urlLabel}
                        checked={isChecked}
                        linkReference={item.linkReference}
                        linkable={item.linkable}
                        onChange={(_checked, e) => {
                            onChange(item, isChecked, e);
                        }}
                        text={item.displayName}
                        count={item.count}
                        isHidden={item.isHidden}
                    />
                );
            })}
        </>
    );
};

SbSharedRefineMenuMultiSelectComponent.propTypes = {
    appliedFilterValues: PropTypes.array.isRequired,
    valuesToShow: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch, { filterName }) {
    return {
        onChange(filterValue, isDeselected, event) {
            dispatch(updateUriRef({ filterName, filterValue, isDeselected, event }));
        },
    };
}

export const SbSharedRefineMenuMultiSelect = connect(
    null,
    mapDispatchToProps
)(SbSharedRefineMenuMultiSelectComponent);
