/**
 * @generated SignedSource<<6c1200cf3bd4b4b75192aaacec1af7a6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbSharedRefineMenuFilterFramingOld_itemSearch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuFramingInput_itemSearch">;
  readonly " $fragmentType": "SbSharedRefineMenuFilterFramingOld_itemSearch";
};
export type SbSharedRefineMenuFilterFramingOld_itemSearch$key = {
  readonly " $data"?: SbSharedRefineMenuFilterFramingOld_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuFilterFramingOld_itemSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbSharedRefineMenuFilterFramingOld_itemSearch",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuFramingInput_itemSearch"
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "aab0c49712efeb5970fc8915174ce07b";

export default node;
