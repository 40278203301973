/**
 * @generated SignedSource<<0f6208530ad1917748f2a8bd87d1209c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbSharedRefineMenuRingSize_filters$data = ReadonlyArray<{
  readonly localizedFilterName: string | null;
  readonly name: string | null;
  readonly totalCount: number | null;
  readonly values: ReadonlyArray<{
    readonly displayName: string | null;
    readonly hexCode: string | null;
    readonly linkReference: string | null;
    readonly properties: {
      readonly mm: string | null;
      readonly us: string | null;
    } | null;
    readonly urlLabel: string | null;
  } | null> | null;
  readonly " $fragmentType": "SbSharedRefineMenuRingSize_filters";
}>;
export type SbSharedRefineMenuRingSize_filters$key = ReadonlyArray<{
  readonly " $data"?: SbSharedRefineMenuRingSize_filters$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuRingSize_filters">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "SbSharedRefineMenuRingSize_filters",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "localizedFilterName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SearchBrowseFilterValue",
      "kind": "LinkedField",
      "name": "values",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "urlLabel",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "linkReference",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hexCode",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "FilterPropertiesType",
          "kind": "LinkedField",
          "name": "properties",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "mm",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "us",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SearchBrowseFilter",
  "abstractKey": null
};

(node as any).hash = "529f9774aaba9c8d91b71dbb08e7bb14";

export default node;
