import { createFragmentContainer, graphql } from 'react-relay/legacy';

// components
import { SbSharedRefineMenuInputRangeDimensions } from '../../SbSharedRefineMenu/SbSharedRefineMenuInputRange/SbSharedRefineMenuInputRangeDimensions';

export const SbRespRefineMenuFilterDimensionsComponent = props => (
    <SbSharedRefineMenuInputRangeDimensions
        {...props}
        size="medium"
        showInvalidRangeErrorMessage={false}
    />
);

export const SbRespRefineMenuFilterDimensions = createFragmentContainer(
    SbRespRefineMenuFilterDimensionsComponent,
    {
        itemSearch: graphql`
            fragment SbRespRefineMenuFilterDimensions_itemSearch on ItemSearchQueryConnection {
                ...SbSharedRefineMenuInputRangeDimensions_itemSearch
            }
        `,
        filters: graphql`
            fragment SbRespRefineMenuFilterDimensions_filters on SearchBrowseFilter
            @relay(plural: true) {
                ...SbSharedRefineMenuInputRangeDimensions_filters
            }
        `,
        user: graphql`
            fragment SbRespRefineMenuFilterDimensions_user on User {
                ...SbSharedRefineMenuInputRangeDimensions_user
            }
        `,
    }
);
