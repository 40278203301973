/**
 * @generated SignedSource<<9c8dd4c98f5424730190f28fc25f956b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type sellerBrandingHelpers_seller$data = {
  readonly isRewarded: boolean | null;
  readonly " $fragmentType": "sellerBrandingHelpers_seller";
};
export type sellerBrandingHelpers_seller$key = {
  readonly " $data"?: sellerBrandingHelpers_seller$data;
  readonly " $fragmentSpreads": FragmentRefs<"sellerBrandingHelpers_seller">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "sellerBrandingHelpers_seller"
};

(node as any).hash = "0a2dcf0f8b9c84acf22e2feff7899b13";

export default node;
