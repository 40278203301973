import { Suspense } from 'react';
import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { SbSharedRefineMenuShippingFiltersWrapperLazy } from '../SbSharedRefineMenuShippingFilters/SbSharedRefineMenuShippingFiltersWrapperLazy';

export const SbSharedRefineMenuFilterDeliveryOptionsComponent = props => {
    const { isClient, currency, user, itemSearch, submitOnBlur, refreshOnSumbit } = props;
    const { appliedFilters = [] } = itemSearch;

    return isClient ? (
        <Suspense fallback="">
            <SbSharedRefineMenuShippingFiltersWrapperLazy
                user={user}
                currency={currency}
                submitOnBlur={submitOnBlur}
                appliedFilterNames={new Set(appliedFilters.map(f => f?.name))}
                refreshOnSumbit={refreshOnSumbit}
            />
        </Suspense>
    ) : null;
};

SbSharedRefineMenuFilterDeliveryOptionsComponent.propTypes = {
    user: PropTypes.object,
    isClient: PropTypes.bool,
    currency: PropTypes.string.isRequired,
    itemSearch: PropTypes.object.isRequired,
    submitOnBlur: PropTypes.bool,
    refreshOnSumbit: PropTypes.bool,
};

export const SbSharedRefineMenuFilterDeliveryOptions = createFragmentContainer(
    SbSharedRefineMenuFilterDeliveryOptionsComponent,
    {
        user: graphql`
            fragment SbSharedRefineMenuFilterDeliveryOptions_user on User {
                ...SbSharedRefineMenuShippingFiltersWrapper_user
            }
        `,
        itemSearch: graphql`
            fragment SbSharedRefineMenuFilterDeliveryOptions_itemSearch on ItemSearchQueryConnection {
                appliedFilters {
                    name
                }
            }
        `,
    }
);
