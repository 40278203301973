import { FC } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import Helmet from 'react-helmet';

import { SharedHeadTitleTag_metadata$data } from './__generated__/SharedHeadTitleTag_metadata.graphql';

type Props = { metadata: SharedHeadTitleTag_metadata$data };

const SharedHeadTitleTagComponent: FC<Props> = ({ metadata }) => {
    const { title, topQueries } = metadata;
    const titleWithTopQueries =
        topQueries && topQueries.length > 0 ? `${title} | ${topQueries.join(', ')}` : title;

    return <Helmet title={titleWithTopQueries || ''} />;
};

export const SharedHeadTitleTag = createFragmentContainer(SharedHeadTitleTagComponent, {
    metadata: graphql`
        fragment SharedHeadTitleTag_metadata on MetaData {
            title
            topQueries
        }
    `,
});
