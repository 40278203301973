import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { trackSeeMoreClick } from '../../../utils/tracking/searchBrowse/filterTracking';
import classnames from 'classnames';

// components
import { FormattedMessage } from 'dibs-react-intl';
import { Link } from 'dibs-elements/exports/Link';

// styles
import styles from './SbSharedRefineMenuExpandableItemList.scss';

export class SbSharedRefineMenuExpandableItemList extends Component {
    constructor() {
        super();

        this.state = {
            isExpanded: false,
        };

        this.onClick = this.onClick.bind(this);
    }

    onClick(e) {
        e.preventDefault();
        this.toggleExpand();
    }

    toggleExpand() {
        this.setState(({ isExpanded }) => {
            if (!isExpanded) {
                trackSeeMoreClick(this.props.filterName);
            }
            return {
                isExpanded: !isExpanded,
            };
        });
    }

    render() {
        const { availableFilterValues, collapsedItemCount } = this.props;
        const { isExpanded } = this.state;
        const canSeeMore = availableFilterValues.length > collapsedItemCount;

        const valuesToShow = availableFilterValues.map((filter, index) => ({
            ...filter,
            isHidden: isExpanded ? false : collapsedItemCount <= index,
        }));

        const buttonClass = classnames(styles.button, {
            [styles.buttonSpacingTop]: !isExpanded && collapsedItemCount !== 0,
        });

        return (
            <Fragment>
                {this.props.children({ valuesToShow })}
                {canSeeMore && (
                    <Link
                        buyerLinkType="standardLink"
                        className={buttonClass}
                        onClick={this.onClick}
                        ariaExpanded={isExpanded}
                        dataTn={`see-${isExpanded ? 'less' : 'more'}`}
                    >
                        <FormattedMessage
                            id="sb.SbSharedRefineMenu.typeahead.seeMore"
                            defaultMessage={`{isExpanded, select,
                                true {See Less}
                                other {See More}
                            }`}
                            values={{ isExpanded }}
                        />
                    </Link>
                )}
            </Fragment>
        );
    }
}

SbSharedRefineMenuExpandableItemList.propTypes = {
    availableFilterValues: PropTypes.array.isRequired,
    collapsedItemCount: PropTypes.number.isRequired,
    filterName: PropTypes.string.isRequired,
    children: PropTypes.func.isRequired,
};

SbSharedRefineMenuExpandableItemList.defaultProps = {
    collapsedItemCount: 5,
};
