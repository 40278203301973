/**
 * @generated SignedSource<<6c541e454d8a654e1973d1080ae93afb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SharedHeadMetaTags_items$data = ReadonlyArray<{
  readonly photos: ReadonlyArray<{
    readonly versions: ReadonlyArray<{
      readonly webPath: string | null;
    } | null> | null;
  } | null> | null;
  readonly " $fragmentType": "SharedHeadMetaTags_items";
}>;
export type SharedHeadMetaTags_items$key = ReadonlyArray<{
  readonly " $data"?: SharedHeadMetaTags_items$data;
  readonly " $fragmentSpreads": FragmentRefs<"SharedHeadMetaTags_items">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "SharedHeadMetaTags_items",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "limit",
          "value": 1
        }
      ],
      "concreteType": "ItemPhoto",
      "kind": "LinkedField",
      "name": "photos",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ItemPhotoVersion",
          "kind": "LinkedField",
          "name": "versions",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "webPath",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "photos(limit:1)"
    }
  ],
  "type": "Item",
  "abstractKey": null
};

(node as any).hash = "61aad961250b67c67db1a2d1523c69b7";

export default node;
