import { Component } from 'react';
import { connect } from 'react-redux';
import { IntlContext } from 'dibs-react-intl';
import get from 'lodash.get';
import PropType from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { localStorage } from 'dibs-browser-storage';
import { getFilterValues } from '../../SbSharedRefineMenu/sbSharedRefineMenuHelpers';

import { createNewFilterUriRef } from '../../../utils/uriUtils';

/* Actions */
import { updateMeasurementUnit } from '../../../actions/measurementUnitActions';
import { updateUriRef } from '../../../actions/filterActions';

/* Mutation */
import { SharedEditMeasurementUnitPreference as EditMeasurementUnitPreferenceMutation } from '../../../mutations/SharedEditMeasurementUnitPreference';

/* Components */
import { SbSharedRefineMenuInputRadio } from '../../SbSharedRefineMenu/SbSharedRefineMenuInput/SbSharedRefineMenuInputRadio';

/* Styles */
import styles from './SbSharedRefineMenuDimensionUnitRadioSelect.scss';

/* Constants */
import {
    MEASUREMENT_UNITS,
    LOCAL_STORAGE_KEY,
    abbreviations,
} from '../../../constants/measurementUnits';
import { MEASUREMENT_UNIT } from '../../SbSharedRefineMenu/sbSharedRefineMenuConstants';

class SbSharedRefineMenuDimensionUnitRadioSelectComponent extends Component {
    constructor(props) {
        super(props);

        this.initialUnitPreference = '';

        this.handleMeasurementUnitChange = this.handleMeasurementUnitChange.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!this.initialUnitPreference) {
            const appliedFilterPreference = this.getAppliedFilterMeasurementUnit(nextProps);

            // measurement-unit query param overrides user preference
            this.initialUnitPreference =
                appliedFilterPreference || this.getUserMeasurementUnitPreference(nextProps);

            if (this.initialUnitPreference) {
                const newMeasurementUnit = this.initialUnitPreference.toUpperCase();

                // update measurement unit in the redux store
                this.props.updateMeasurementUnit(newMeasurementUnit);

                // don't save if measurement-unit comes from query param
                if (!appliedFilterPreference) {
                    this.saveMeasurementUnitPreference(newMeasurementUnit);
                }
            }
        }
    }

    getUserMeasurementUnitPreference(nextProps) {
        const { fetchUser, user } = nextProps;
        let unitPreference = '';

        /*
         * Figure out correct measurement unit to use. The correct precedence is:
         * 1) logged in user measurement unit preference from service
         * 2) measurement unit from local storage
         */
        if (fetchUser) {
            // if we have a user wait until the user data has been fetched
            if (user) {
                unitPreference = this.getUserProfileMeasurementUnit(nextProps);
            }
        } else {
            unitPreference = this.getLocalStorageMeasurementUnit() || '';
        }

        return unitPreference;
    }

    getAppliedFilterMeasurementUnit(props) {
        const appliedFilters = get(props, 'itemSearch.appliedFilters') || [];
        const filterValue = getFilterValues(appliedFilters, 'measurement-unit');

        return get(filterValue, '[0].urlLabel');
    }

    getUserProfileMeasurementUnit(props) {
        return get(props, 'user.preferences.measurementUnit');
    }

    getLocalStorageMeasurementUnit() {
        return localStorage.getItem(LOCAL_STORAGE_KEY);
    }

    updateUserPreferences(newMeasurementUnit, callback = () => {}) {
        const { relay } = this.props;
        const userServiceId = get(this.props, 'user.serviceId');
        const userProfileMeasurementUnit = this.getUserProfileMeasurementUnit(this.prop);

        if (userServiceId && userProfileMeasurementUnit !== newMeasurementUnit) {
            EditMeasurementUnitPreferenceMutation.commit(relay.environment, {
                userServiceId,
                measurementUnit: newMeasurementUnit,
                onCompleted: callback,
            });
        } else {
            callback();
        }
    }

    saveMeasurementUnitPreference(newMeasurementUnit, callback) {
        // save to local storage
        localStorage.setItem(LOCAL_STORAGE_KEY, newMeasurementUnit);
        // commit mutation to update user preferences
        this.updateUserPreferences(newMeasurementUnit, callback);
    }

    handleMeasurementUnitChange(newMeasurementUnit, event) {
        // persist change
        this.saveMeasurementUnitPreference(newMeasurementUnit, () => {
            // apply measurement-unit filter
            this.props.applyMeasurementUnit(newMeasurementUnit, this.props.generatedUriRef, event);
            // update measurement unit in the redux store
            this.props.updateMeasurementUnit(newMeasurementUnit);
        });
    }

    render() {
        const { selectedMeasurementUnit } = this.props;

        return (
            <div className={styles.container}>
                {MEASUREMENT_UNITS.map(unit => {
                    return (
                        <div className={styles.radioInputs} key={unit}>
                            <SbSharedRefineMenuInputRadio
                                value={unit}
                                name={MEASUREMENT_UNIT}
                                linkable={false}
                                onChange={this.handleMeasurementUnitChange}
                                checked={unit === selectedMeasurementUnit}
                                text={this.context.formatMessage(abbreviations[unit])}
                            />
                        </div>
                    );
                })}
            </div>
        );
    }
}

SbSharedRefineMenuDimensionUnitRadioSelectComponent.contextType = IntlContext;

SbSharedRefineMenuDimensionUnitRadioSelectComponent.contextTypes = {
    formatMessage: PropType.func,
};

SbSharedRefineMenuDimensionUnitRadioSelectComponent.propTypes = {
    user: PropType.object,
    relay: PropType.object.isRequired,
    updateMeasurementUnit: PropType.func.isRequired,
    applyMeasurementUnit: PropType.func.isRequired,
    selectedMeasurementUnit: PropType.string.isRequired,
    fetchUser: PropType.bool.isRequired,
    generatedUriRef: PropType.string,
};

function mapStateToProps(state) {
    return {
        fetchUser: state.relayVariables.variables.fetchUser,
        generatedUriRef: get(state, 'filters.generatedUriRef'),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateMeasurementUnit(measurementUnit) {
            dispatch(updateMeasurementUnit(measurementUnit));
        },
        applyMeasurementUnit(measurementUnit, generatedUriRef, event) {
            const filterName = MEASUREMENT_UNIT;
            const filterValue = {
                urlLabel: measurementUnit,
            };
            const uriRef = createNewFilterUriRef(
                generatedUriRef,
                filterName,
                measurementUnit.toLowerCase()
            );
            dispatch(updateUriRef({ filterName, filterValue, uriRef, event }));
        },
    };
}

SbSharedRefineMenuDimensionUnitRadioSelectComponent = connect(
    mapStateToProps,
    mapDispatchToProps
)(SbSharedRefineMenuDimensionUnitRadioSelectComponent);

export const SbSharedRefineMenuDimensionUnitRadioSelect = createFragmentContainer(
    SbSharedRefineMenuDimensionUnitRadioSelectComponent,
    {
        itemSearch: graphql`
            fragment SbSharedRefineMenuDimensionUnitRadioSelect_itemSearch on ItemSearchQueryConnection {
                appliedFilters {
                    name
                    values {
                        urlLabel
                    }
                }
            }
        `,
        user: graphql`
            fragment SbSharedRefineMenuDimensionUnitRadioSelect_user on User {
                serviceId
                preferences {
                    measurementUnit
                }
            }
        `,
    }
);
