import { createFragmentContainer, graphql } from 'react-relay/legacy';

// components
import { SbSharedRefineMenuMultiSelectList } from '../SbSharedRefineMenuMultiSelect/SbSharedRefineMenuMultiSelectList';

export const SbSharedRefineMenuFilterMatchingSetsComponent = props => {
    return <SbSharedRefineMenuMultiSelectList {...props} />;
};

export const SbSharedRefineMenuFilterMatchingSets = createFragmentContainer(
    SbSharedRefineMenuFilterMatchingSetsComponent,
    {
        itemSearch: graphql`
            fragment SbSharedRefineMenuFilterMatchingSets_itemSearch on ItemSearchQueryConnection {
                ...SbSharedRefineMenuMultiSelectList_itemSearch
            }
        `,
    }
);
