import { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'dibs-react-intl';
import PropTypes from 'prop-types';
import { updateUriRef } from '../../../actions/filterActions';
import {
    formatRange,
    parseRangeDisplayValue,
    constructCaratWeightStatsRange,
    roundCaratWeightRange,
} from '../sbSharedRefineMenuHelpers';

import { Input } from 'dibs-elements/exports/Input';
import { Button } from 'dibs-elements/exports/Button';
import ArrowRight from 'dibs-icons/exports/legacy/ArrowRight';

// styles
import styles from '../../SbSharedRefineMenu/SbSharedRefineMenuInputRange/SbSharedRefineMenuInputRange.scss';

export class SbSharedRefineMenuCaratWeightRangeComponent extends Component {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.applyValue = this.applyValue.bind(this);

        this.state = {
            error: false,
            minValue: props.min,
            maxValue: props.max,
        };
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        this.validateMinMax({
            minValue: newProps.min,
            maxValue: newProps.max,
        });

        this.setState({
            minValue: newProps.min,
            maxValue: newProps.max,
        });
    }

    validateMinMax({ minValue, maxValue, shouldApply, event, ga }) {
        const { applyOnBlur } = this.props;
        const error = parseInt(minValue) > parseInt(maxValue);
        if (!error && shouldApply && applyOnBlur) {
            // only apply if the value of min or max has changed
            this.props.onApply([minValue, maxValue], event, ga);
        }

        this.setState({ error });
    }

    onChange(name, e) {
        if (e && typeof e.preventDefault === 'function') {
            e.preventDefault();
        }

        const { availableMin, availableMax } = this.props;
        let { minValue, maxValue } = this.state;

        if (name === 'min') {
            const value =
                parseInt(e.target.value) < parseInt(availableMin) ? availableMin : e.target.value;
            minValue = value;
            this.setState({ minValue });
        } else {
            const value =
                parseInt(e.target.value) > parseInt(availableMax) ? availableMax : e.target.value;
            maxValue = value;
            this.setState({ maxValue });
        }

        this.validateMinMax({
            minValue,
            maxValue,
            shouldApply: false,
            event: e,
        });
    }

    onBlur(e) {
        if (this.state.maxValue !== this.props.max || this.state.minValue !== this.props.min) {
            this.validateMinMax({
                minValue: this.state.minValue,
                maxValue: this.state.maxValue,
                shouldApply: true,
                event: e,
                // all blur events are NOT interactive
                // passing isInteractiveEvent along to count as interactive
                ga: {
                    isInteractiveEvent: true,
                },
            });
        }
    }

    applyValue(e) {
        if (e && typeof e.preventDefault === 'function') {
            e.preventDefault();
        }
        const { minValue, maxValue, error } = this.state;

        if (!error) {
            this.props.onApply([minValue, maxValue], e);
        }
    }

    render() {
        const inputProps = {
            horizontalSpacing: 'small',
            size: 'large',
            showIcons: false,
            hasError: this.state.error,
            maxLength: 17,
        };

        return (
            <div>
                <div className={styles.container}>
                    <Input
                        name="carat-weight-min"
                        onChange={e => this.onChange('min', e)}
                        onBlur={this.onBlur}
                        value={this.state.minValue.toString()}
                        dataTn={'search-browse-carat-weight-input-min'}
                        {...inputProps}
                    />
                    <div className={styles.divider}>
                        <FormattedMessage
                            id="sb.SbSharedRefineMenuCaratWeight.inputRange.to"
                            defaultMessage="to"
                        />
                    </div>
                    <Input
                        name="carat-weight-max"
                        onChange={e => this.onChange('max', e)}
                        onBlur={this.onBlur}
                        value={this.state.maxValue.toString()}
                        dataTn={'search-browse-carat-weight-input-max'}
                        {...inputProps}
                    />
                    {!this.props.applyOnBlur && (
                        <Button
                            size="large"
                            type="secondary"
                            onClick={this.applyValue}
                            className={styles.inputRangeButton}
                            dataTn={'search-browse-carat-weight-apply'}
                        >
                            <ArrowRight className={styles.arrow} />
                        </Button>
                    )}
                </div>
                {this.state.error && (
                    <div className={styles.error}>
                        <FormattedMessage
                            id="sb.SbSharedRefineMenuCaratWeight.inputRange.error"
                            defaultMessage="Min value may not exceed max"
                        />
                    </div>
                )}
            </div>
        );
    }
}

SbSharedRefineMenuCaratWeightRangeComponent.defaultProps = {
    onApply: () => {},
    hasError: false,
    applyOnBlur: false,
};

SbSharedRefineMenuCaratWeightRangeComponent.propTypes = {
    onApply: PropTypes.func,
    applyOnBlur: PropTypes.bool,
    min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    availableMin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    availableMax: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

function mapStateToProps(state, props) {
    const availableDisplayValue = props?.filter?.values?.[0]?.displayName;
    const appliedDisplayValue = props?.values?.[0]?.urlLabel;

    const [min, max] = constructCaratWeightStatsRange(appliedDisplayValue, availableDisplayValue);
    const [parsedMin, parsedMax] = parseRangeDisplayValue(availableDisplayValue);
    const [availableMin, availableMax] = roundCaratWeightRange(parsedMin, parsedMax);

    return { min, max, availableMin, availableMax };
}

function mapDispatchToProps(dispatch, props) {
    return {
        onApply(minMax, event, ga) {
            const uriRef = formatRange(props?.filter?.values?.[0]?.linkReference || '', minMax);
            const filterName = props?.filter?.name;
            dispatch(updateUriRef({ filterName, uriRef, event, ga }));
        },
    };
}

export const SbSharedRefineMenuCaratWeightRange = connect(
    mapStateToProps,
    mapDispatchToProps
)(SbSharedRefineMenuCaratWeightRangeComponent);
