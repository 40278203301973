import { useId, FC } from 'react';
import { ArrayElement } from 'dibs-ts-utils/exports/ArrayElement';

import { useFragment, graphql } from 'react-relay';
import { MEASUREMENT_UNIT, WIDTH, HEIGHT } from '../sbSharedRefineMenuConstants';
import { useIntl, defineMessages, IntlShape } from 'dibs-react-intl';
import { useMeasurementUnit } from '../../useMeasurementUnit';
import { filterNulls } from 'dibs-ts-utils/exports/filterNulls';
import { IN, CM, abbreviations } from '../../../constants/measurementUnits';

import { SbSharedRefineMenuMultiSelectList } from '../SbSharedRefineMenuMultiSelect/SbSharedRefineMenuMultiSelectList';
import { SbSharedRefineMenuDimensionUnitRadioSelect } from '../SbSharedRefineMenuDimensionUnitRadioSelect/SbSharedRefineMenuDimensionUnitRadioSelect';
import { SbSharedRefineMenuDimensionFacet } from '../SbSharedRefineMenuDimensionFacet/SbSharedRefineMenuDimensionFacet';
import { SbSharedRefineMenuInputRange } from '../SbSharedRefineMenuInputRange/SbSharedRefineMenuInputRange';

import { SbSharedRefineMenuFilterArtSize_itemSearch$key } from './__generated__/SbSharedRefineMenuFilterArtSize_itemSearch.graphql';
import { SbSharedRefineMenuFilterArtSize_user$key } from './__generated__/SbSharedRefineMenuFilterArtSize_user.graphql';
import { SbSharedRefineMenuFilterArtSize_filters$key } from './__generated__/SbSharedRefineMenuFilterArtSize_filters.graphql';
import { SbSharedRefineMenuFilters_filters$data } from './__generated__/SbSharedRefineMenuFilters_filters.graphql';

type ArtSizeValues = ArrayElement<SbSharedRefineMenuFilters_filters$data>['values'];

type MeasurementUnit = 'CM' | 'IN';

const messages = defineMessages({
    artSizeRange: {
        id: 'sb.SbSharedRefineMenuFilterArtSize.range',
        defaultMessage: '{displayName} ({formattedMin}—{formattedMax})',
    },
    artSizeRangeMin: {
        id: 'sb.SbSharedRefineMenuFilterArtSize.rangeMin',
        defaultMessage: '{displayName} ({formattedMin}+)',
    },
    artSizeRangeMax: {
        id: 'sb.SbSharedRefineMenuFilterArtSize.rangeMax',
        defaultMessage: '{displayName} (<{formattedMax})',
    },
    overallWidthTitle: {
        id: 'sb.SbSharedRefineMenuFilterArtSize.overallWidthTitle',
        defaultMessage: 'Overall Width',
    },
    overallHeightTitle: {
        id: 'sb.SbSharedRefineMenuFilterArtSize.overallHeightTitle',
        defaultMessage: 'Overall Height',
    },
    dimensionFromLabel: {
        id: 'sb.SbSharedRefineMenuFilterArtSize.dimensionFromLabel',
        defaultMessage: '{measurementUnit} min',
    },
    dimensionToLabel: {
        id: 'sb.SbSharedRefineMenuFilterArtSize.dimensionToLabel',
        defaultMessage: '{measurementUnit} max',
    },
});

type FormatMeasurementArgs = {
    intl: IntlShape;
    value: string | null;
    measurementUnit: MeasurementUnit;
};

const formatMeasurement = ({
    intl,
    value,
    measurementUnit,
}: FormatMeasurementArgs): string | null => {
    if (value === null || value === '0') {
        return null;
    }
    const numericValue = Number(value);

    const values: Record<MeasurementUnit, number> = {
        [IN]: Math.round(numericValue * 10) / 10,
        [CM]: Math.round(numericValue * 2.54 * 10) / 10, // 1 inch = 2.54 cm
    };

    const units: Record<MeasurementUnit, string> = {
        [IN]: 'inch',
        [CM]: 'centimeter',
    };

    return intl.formatNumber(values[measurementUnit], {
        style: 'unit',
        unit: units[measurementUnit],
    });
};

type FormatDisplayNameArgs = {
    intl: IntlShape;
    artSizeValue: ArrayElement<ArtSizeValues>;
    measurementUnit: MeasurementUnit;
};

export const formatDisplayName = ({
    intl,
    artSizeValue,
    measurementUnit,
}: FormatDisplayNameArgs): string => {
    const { displayName, properties } = artSizeValue || {};

    const formattedMin = formatMeasurement({
        intl,
        value: properties?.min || null,
        measurementUnit,
    });
    const formattedMax = formatMeasurement({
        intl,
        value: properties?.max || null,
        measurementUnit,
    });

    if (formattedMax === null) {
        return intl.formatMessage(messages.artSizeRangeMin, { displayName, formattedMin });
    }

    if (formattedMin === null) {
        return intl.formatMessage(messages.artSizeRangeMax, { displayName, formattedMax });
    }

    return intl.formatMessage(messages.artSizeRange, { displayName, formattedMin, formattedMax });
};

type Props = {
    itemSearch: SbSharedRefineMenuFilterArtSize_itemSearch$key;
    filters: SbSharedRefineMenuFilterArtSize_filters$key;
    user: SbSharedRefineMenuFilterArtSize_user$key;
    filterName: string;
    shouldUseExpandableList: boolean;
    values: ArtSizeValues;
    applyOnBlur?: boolean;
    showInvalidRangeErrorMessage?: boolean;
    size?: string;
};

export const SbSharedRefineMenuFilterArtSize: FC<Props> = ({
    user: userRef,
    itemSearch: itemSearchRef,
    filters: filtersRef,
    filterName,
    values,
    shouldUseExpandableList,
    applyOnBlur,
    showInvalidRangeErrorMessage,
    size,
}) => {
    const itemSearch = useFragment(
        graphql`
            fragment SbSharedRefineMenuFilterArtSize_itemSearch on ItemSearchQueryConnection {
                ...SbSharedRefineMenuMultiSelectList_itemSearch
                ...SbSharedRefineMenuInputRange_itemSearch
                ...useMeasurementUnit_itemSearch
                ...SbSharedRefineMenuDimensionUnitRadioSelect_itemSearch
                appliedFilters {
                    name
                    values {
                        linkReference
                    }
                }
            }
        `,
        itemSearchRef
    );

    const user = useFragment(
        graphql`
            fragment SbSharedRefineMenuFilterArtSize_user on User {
                ...useMeasurementUnit_user
                ...SbSharedRefineMenuDimensionUnitRadioSelect_user
            }
        `,
        userRef
    );

    const filters = useFragment(
        graphql`
            fragment SbSharedRefineMenuFilterArtSize_filters on SearchBrowseFilter
            @relay(plural: true) {
                name
                localizedFilterName
                values {
                    urlLabel
                    linkReference
                }
            }
        `,
        filtersRef
    );

    const intl = useIntl();
    const htmlIdBase = useId();
    const [measurementUnit] = useMeasurementUnit({ user, itemSearch });

    const displayedArtSizeValues = (values || []).filter(filterNulls).map(artSizeValue => ({
        ...artSizeValue,
        displayName: formatDisplayName({
            intl,
            artSizeValue,
            measurementUnit,
        }),
    }));

    const dimensionFilters = (filters || []).filter(f => f.name === WIDTH || f.name === HEIGHT);
    const getLocalizedName = (name: string | null): string => {
        const message = name === WIDTH ? messages.overallWidthTitle : messages.overallHeightTitle;
        return intl.formatMessage(message);
    };

    return (
        <>
            <SbSharedRefineMenuDimensionUnitRadioSelect
                user={user}
                itemSearch={itemSearch}
                selectedMeasurementUnit={measurementUnit}
            />

            <div>
                <SbSharedRefineMenuMultiSelectList
                    filterName={filterName}
                    values={displayedArtSizeValues}
                    shouldUseExpandableList={shouldUseExpandableList}
                    itemSearch={itemSearch}
                />
            </div>
            {(dimensionFilters || []).map(dimensionFilter => {
                const { name } = dimensionFilter;
                const id = `${htmlIdBase}-${name}`;
                const localizedFilterName = getLocalizedName(name);
                const localizedMeasurementUnit = intl.formatMessage(
                    abbreviations[measurementUnit as MeasurementUnit]
                );

                return (
                    <SbSharedRefineMenuDimensionFacet
                        id={id}
                        key={name}
                        title={localizedFilterName}
                    >
                        <SbSharedRefineMenuInputRange
                            labelFrom={intl.formatMessage(messages.dimensionFromLabel, {
                                measurementUnit: localizedMeasurementUnit,
                            })}
                            labelTo={intl.formatMessage(messages.dimensionToLabel, {
                                measurementUnit: localizedMeasurementUnit,
                            })}
                            ariaDescribedBy={id}
                            size={size}
                            filterName={name}
                            filter={dimensionFilter}
                            applyOnBlur={applyOnBlur}
                            statKey={measurementUnit}
                            additionalFilter={MEASUREMENT_UNIT}
                            itemSearch={itemSearch}
                            showInvalidRangeErrorMessage={showInvalidRangeErrorMessage}
                        />
                    </SbSharedRefineMenuDimensionFacet>
                );
            })}
        </>
    );
};
