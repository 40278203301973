import { Component } from 'react';
import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import get from 'lodash.get';
import { SbSharedRefineMenuTypeahead } from './SbSharedRefineMenuTypeahead';
import { SbSharedRefineMenuTypeaheadControls } from './SbSharedRefineMenuTypeaheadControls';
import { connect } from 'react-redux';
import { updateUriRef } from '../../../actions/filterActions';
import { decode64 } from '../../../utils/decode';

export class SbSharedRefineMenuTypeaheadContainerComponent extends Component {
    constructor() {
        super();

        this.state = {
            isTypeaheadActive: false,
        };

        this.handleToggleClick = this.handleToggleClick.bind(this);
        this.handleClearClick = this.handleClearClick.bind(this);
        this.toggleTypeahead = this.toggleTypeahead.bind(this);
    }

    handleToggleClick(e) {
        e.preventDefault();
        this.toggleTypeahead();
    }

    handleClearClick(e) {
        e.preventDefault();
        this.props.clearFilters();
    }

    toggleTypeahead() {
        this.setState({
            isTypeaheadActive: !this.state.isTypeaheadActive,
        });
    }

    isFilterInList(filterValue, list) {
        return list.findIndex(value => value.urlLabel === filterValue.urlLabel) > -1;
    }

    render() {
        const {
            availableFilterValues,
            collapsedItemCount,
            appliedFilterValues,
            viewer,
            itemSearch,
            filterName,
            RefineMenuTypeaheadWrapper,
            iconMap,
        } = this.props;
        const { isTypeaheadActive } = this.state;

        /**
         * We never show more available filters that are defined in collapsedItemCount, so we
         * can discard everything else.
         */
        const showClearButton = !!appliedFilterValues.length;

        let valuesToShow = appliedFilterValues.reduceRight((result, value, index) => {
            /**
             * Check if the filter value is not present in the suggested/available filter
             * values.
             */
            if (result.length && !this.isFilterInList(value, result)) {
                /**
                 * If we're here - this means the value was probably applied from the
                 * typeahead or it's a value that no longer fits in the available values and should
                 * be moved to the top.
                 * Find filter in availableFilterValues array to ensure the correct counts are used
                 */
                const availableFilter = availableFilterValues.find(
                    filter => filter.urlLabel === value.urlLabel
                );
                result.unshift(availableFilter || value);
                /**
                 * It's possible that we will have more applied values than the number specified in
                 * `collapsedItemCount`, so we need to guard againts removing them.
                 */
                if (index < collapsedItemCount) {
                    result.pop();
                }
            }

            return result;
        }, availableFilterValues.slice(0, collapsedItemCount));

        if (isTypeaheadActive) {
            valuesToShow = valuesToShow.filter(value =>
                this.isFilterInList(value, appliedFilterValues)
            );
        }
        const showTypeAheadControls = !isTypeaheadActive;
        return (
            <>
                {this.props.children({ valuesToShow })}
                {isTypeaheadActive && (
                    <SbSharedRefineMenuTypeahead
                        viewer={viewer}
                        filterName={filterName}
                        excludedDisplayNames={appliedFilterValues.map(value => value.displayName)}
                        handleToggling={this.handleToggleClick}
                        onResultClick={this.toggleTypeahead}
                        RefineMenuTypeaheadWrapper={RefineMenuTypeaheadWrapper}
                        iconMap={iconMap}
                    />
                )}
                {showTypeAheadControls && (
                    <SbSharedRefineMenuTypeaheadControls
                        filterName={filterName}
                        categoryL1={get(itemSearch, 'topCategoryL1') || ''}
                        showSearch
                        showClear={showClearButton}
                        onToggleClick={this.handleToggleClick}
                        onClearClick={this.handleClearClick}
                    />
                )}
            </>
        );
    }
}

SbSharedRefineMenuTypeaheadContainerComponent.propTypes = {
    availableFilterValues: PropTypes.array.isRequired,
    collapsedItemCount: PropTypes.number.isRequired,
    appliedFilterValues: PropTypes.array.isRequired,
    viewer: PropTypes.object.isRequired,
    itemSearch: PropTypes.object.isRequired,
    filterName: PropTypes.string.isRequired,
    children: PropTypes.func.isRequired,
    clearFilters: PropTypes.func.isRequired,
    RefineMenuTypeaheadWrapper: PropTypes.func,
    iconMap: PropTypes.object,
};

SbSharedRefineMenuTypeaheadContainerComponent.defaultProps = {
    collapsedItemCount: 5,
};

function mapDispatchToProps(dispatch, { filterName, itemSearch }) {
    return {
        clearFilters() {
            const { appliedFilters } = itemSearch;
            const appliedFilter = appliedFilters.find(filter => filter.name === filterName);
            dispatch(updateUriRef({ uriRef: decode64(appliedFilter.clearAllLinkReference) }));
        },
    };
}

export const SbSharedRefineMenuTypeaheadContainer = createFragmentContainer(
    connect(null, mapDispatchToProps)(SbSharedRefineMenuTypeaheadContainerComponent),
    {
        viewer: graphql`
            fragment SbSharedRefineMenuTypeaheadContainer_viewer on Viewer {
                ...SbSharedRefineMenuTypeahead_viewer
            }
        `,
        itemSearch: graphql`
            fragment SbSharedRefineMenuTypeaheadContainer_itemSearch on ItemSearchQueryConnection {
                topCategoryL1
                appliedFilters {
                    name
                    clearAllLinkReference
                }
            }
        `,
    }
);
