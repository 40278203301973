import PropTypes from 'prop-types';
import classNames from 'classnames';
import slugify from 'dibs-slugify/exports/slugify';

// components
import { AbsoluteAnchor } from '../../../global/AbsoluteAnchor/AbsoluteAnchor';

// styles
import styles from './SbRespRefineMenuStoneCutSelectInput.scss';

export const SbRespRefineMenuStoneCutSelectInput = props => {
    const {
        stoneCut,
        count,
        checked,
        linkReference,
        linkable,
        text,
        stoneCutMap,
        isHidden,
        onClick,
    } = props;

    const containerClass = classNames(styles.container, {
        'is-hidden': isHidden,
    });
    const StoneCutIcon = stoneCutMap[stoneCut];
    const stoneCutClasses = classNames({
        [styles.checked]: checked,
    });

    const id = text.replace(/\s/g, '-');

    return (
        <div className={containerClass}>
            <input
                id={id}
                className={styles.checkbox}
                checked={checked}
                type="checkbox"
                onChange={onClick}
            />
            <label htmlFor={id} className={styles.label}>
                <AbsoluteAnchor
                    linkReference={linkReference}
                    linkable={linkable}
                    className={styles.input}
                    dataTn={`${slugify(text)}-link${checked ? '-checked' : ''}`}
                    onClick={e => {
                        e.preventDefault();
                    }}
                    tabIndex={-1}
                    inert
                >
                    <div className={styles.stoneCutContainer}>
                        {StoneCutIcon && <StoneCutIcon className={stoneCutClasses} />}
                    </div>
                    {text}
                </AbsoluteAnchor>
            </label>
            {count && <div className={styles.count}>{count}</div>}
        </div>
    );
};

SbRespRefineMenuStoneCutSelectInput.propTypes = {
    count: PropTypes.number,
    checked: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    stoneCutMap: PropTypes.object,
    text: PropTypes.string.isRequired,
    linkReference: PropTypes.string.isRequired,
    linkable: PropTypes.bool.isRequired,
    stoneCut: PropTypes.string.isRequired,
    isHidden: PropTypes.bool,
};
