/**
 * @generated SignedSource<<b360886e5c9123a2103421327896f4f7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbSharedRefineMenuFilterFraming_itemSearch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuFramingInput_itemSearch">;
  readonly " $fragmentType": "SbSharedRefineMenuFilterFraming_itemSearch";
};
export type SbSharedRefineMenuFilterFraming_itemSearch$key = {
  readonly " $data"?: SbSharedRefineMenuFilterFraming_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuFilterFraming_itemSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbSharedRefineMenuFilterFraming_itemSearch",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuFramingInput_itemSearch"
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "efd80c34ad6ef1e1fa253506ab6dcce2";

export default node;
