/**
 * @generated SignedSource<<d37a95922488f761e29c26187d3afed0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useMeasurementUnit_itemSearch$data = {
  readonly appliedFilters: ReadonlyArray<{
    readonly name: string | null;
    readonly values: ReadonlyArray<{
      readonly urlLabel: string | null;
    } | null> | null;
  } | null> | null;
  readonly " $fragmentType": "useMeasurementUnit_itemSearch";
};
export type useMeasurementUnit_itemSearch$key = {
  readonly " $data"?: useMeasurementUnit_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"useMeasurementUnit_itemSearch">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useMeasurementUnit_itemSearch"
};

(node as any).hash = "2620b9e9552fd0e5e00c63233d3acf5f";

export default node;
