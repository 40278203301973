import { FC } from 'react';
import { useFragment, graphql } from 'react-relay';
import { useIntl, defineMessages } from 'dibs-react-intl';
import { useDispatch } from 'react-redux';
import { updateUriRef } from '../../../actions/filterActions';
import { getFilterValues, hasFilterApplied } from '../sbSharedRefineMenuHelpers';
import { ArrayElement } from 'dibs-ts-utils/exports/ArrayElement';
import { filterNulls } from 'dibs-ts-utils/exports/filterNulls';

// components
import { SbSharedRefineMenuInputCheckbox } from '../../SbSharedRefineMenu/SbSharedRefineMenuInput/SbSharedRefineMenuInputCheckbox';

// Constants
import { ON_SALE, SALE } from '../../SbSharedRefineMenu/sbSharedRefineMenuConstants';

import styles from './SbSharedRefineMenuSaleInput.scss';

const messages = defineMessages({
    title: {
        id: 'sb.SbMobileRefineMenuFilterSale.title',
        defaultMessage: 'On sale',
    },
});

import {
    SbSharedRefineMenuSaleInput_itemSearch$key,
    SbSharedRefineMenuSaleInput_itemSearch$data,
} from './__generated__/SbSharedRefineMenuSaleInput_itemSearch.graphql';

type Filter = NonNullable<
    ArrayElement<NonNullable<SbSharedRefineMenuSaleInput_itemSearch$data['filters']>>
>;
type FilterValues = NonNullable<Filter['values']>;

type Props = {
    itemSearch: SbSharedRefineMenuSaleInput_itemSearch$key;
};

export const SbSharedRefineMenuSaleInput: FC<Props> = ({ itemSearch: itemSearchRef }) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const itemSearch = useFragment(
        graphql`
            fragment SbSharedRefineMenuSaleInput_itemSearch on ItemSearchQueryConnection {
                appliedFilters {
                    name
                    values {
                        linkReference
                    }
                }
                filters {
                    name
                    values {
                        linkReference
                        linkable
                        displayName
                        urlLabel
                        count
                    }
                }
            }
        `,
        itemSearchRef
    );

    const filters = (itemSearch?.filters || []).filter(filterNulls);
    const onSaleFilterValues = getFilterValues(filters, ON_SALE) || [];
    const saleFilterValues: FilterValues =
        onSaleFilterValues.length > 0 ? onSaleFilterValues : getFilterValues(filters, SALE);

    const appliedFilters = (itemSearch?.appliedFilters || []).filter(filterNulls);
    const isSaleFilterApplied = hasFilterApplied(appliedFilters, [SALE]);

    const checkboxes = saleFilterValues.map(filter => {
        const count = filter?.count;
        const urlLabel = filter?.urlLabel;
        return (
            <SbSharedRefineMenuInputCheckbox
                key={urlLabel}
                value={urlLabel || ''}
                linkReference={filter?.linkReference}
                linkable={filter?.linkable}
                checked={isSaleFilterApplied}
                onChange={(checked, event) => {
                    dispatch(
                        updateUriRef({
                            filterName: SALE,
                            filterValue: filter,
                            isDeselected: !checked,
                            event,
                            // TS complains
                            ga: undefined,
                            uriRef: undefined,
                        })
                    );
                }}
                text={intl.formatMessage(messages.title)}
                className={styles.wrapper}
                count={count}
            />
        );
    });
    return <>{checkboxes}</>;
};
