import { createFragmentContainer, graphql } from 'react-relay/legacy';

// components
import { SbSharedRefineMenuMultiSelectList } from '../SbSharedRefineMenuMultiSelect/SbSharedRefineMenuMultiSelectList';

export const SbSharedRefineMenuFilterPeriodComponent = props => (
    <SbSharedRefineMenuMultiSelectList {...props} />
);

export const SbSharedRefineMenuFilterPeriod = createFragmentContainer(
    SbSharedRefineMenuFilterPeriodComponent,
    {
        itemSearch: graphql`
            fragment SbSharedRefineMenuFilterPeriod_itemSearch on ItemSearchQueryConnection {
                ...SbSharedRefineMenuMultiSelectList_itemSearch
            }
        `,
    }
);
