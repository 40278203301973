import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import slugify from 'dibs-slugify/exports/slugify';

//components
import { FormattedNumber } from 'dibs-react-intl';
import { Checkbox } from 'dibs-elements/exports/Checkbox';
import { AbsoluteAnchor } from '../../../global/AbsoluteAnchor/AbsoluteAnchor';

// styles
import styles from './SbSharedRefineMenuInput.scss';

export function SbSharedRefineMenuInputCheckbox(props) {
    const {
        text,
        count,
        className,
        value,
        checked,
        onChange,
        linkReference,
        linkable,
        isHidden,
        isDisabled,
    } = props;
    const containerClass = classnames(styles.container, className, {
        'is-hidden': isHidden,
    });
    const refetchInFlight = useSelector(state => state.filters.refetchInFlight);
    const anchorClass = classnames(styles.anchor, {
        [styles.disabled]: isDisabled || refetchInFlight,
    });
    const shouldDisplayCount = typeof count === 'number';

    // put AbsoluteAnchor inside label to prevent click and focus stealing
    const label = (
        <AbsoluteAnchor
            linkReference={linkReference}
            linkable={linkable}
            className={anchorClass}
            onClick={e => e.preventDefault()}
            inert
        >
            {text}
        </AbsoluteAnchor>
    );

    return (
        <div className={containerClass}>
            <Checkbox
                wrapperClass={styles.inputWrapper}
                labelClass={styles.label}
                value={value}
                checked={checked}
                name={text}
                label={label}
                onChange={onChange}
                disabled={isDisabled || refetchInFlight}
                dataTn={`facet-content-${slugify(text)}`}
            />

            {shouldDisplayCount && (
                <span className={styles.count} data-tn={`facet-count-${slugify(text)}`}>
                    <FormattedNumber value={count} />
                </span>
            )}
        </div>
    );
}

SbSharedRefineMenuInputCheckbox.propTypes = {
    count: PropTypes.number,
    text: PropTypes.string.isRequired,
    className: PropTypes.string,
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    linkReference: PropTypes.string,
    linkable: PropTypes.bool,
    isHidden: PropTypes.bool,
    isDisabled: PropTypes.bool,
};
