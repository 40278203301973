/**
 * @generated SignedSource<<69b23256fa7dd336f61b5950eabeb002>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbSharedRefineMenuFilterMetal_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuMultiSelectSearch_viewer">;
  readonly " $fragmentType": "SbSharedRefineMenuFilterMetal_viewer";
};
export type SbSharedRefineMenuFilterMetal_viewer$key = {
  readonly " $data"?: SbSharedRefineMenuFilterMetal_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuFilterMetal_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbSharedRefineMenuFilterMetal_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuMultiSelectSearch_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "2798c6c2ba1ab8f683b56930ca3d69ce";

export default node;
