import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { useIntl } from 'dibs-react-intl';

import styles from './AbsoluteAnchor.scss';
import { handleLocaleUrl } from 'dibs-intl/exports/urls';

// looks like we have Buffer support on client, though I don't know where it's coming from
// this is here to ensure if that is removed this never breaks in client.
const decode = typeof atob === 'function' ? atob : str => Buffer.from(str, 'base64').toString();
function decode64(encoded = '') {
    try {
        return decode(encoded);
    } catch (e) {
        return undefined;
    }
}

const AbsoluteAnchorComponent = props => {
    // dispatch prop is not used, but it's extracted here,
    // so that otherProps could be safely spread on the <a> elememt
    const {
        children,
        href,
        linkReference,
        linkable,
        localeDomain,
        dataTn,
        dispatch, // eslint-disable-line no-unused-vars
        inert, // inert removes the element from tab order and sets pointer-events: none;
        className,
        ...otherProps
    } = props;
    const intl = useIntl();
    // href may be passed in as an over-ride for the encoded linkReference. Used for 'all' link(s)
    const useHref = handleLocaleUrl(href ? href : decode64(linkReference), intl.locale);
    // If href is undefined or null, React will simply render an empty anchor element,
    // and if localeDomain is not set, it should render href as it is
    const absoluteUrl = localeDomain && useHref ? `https://${localeDomain}${useHref}` : useHref;

    if (linkable === true) {
        return (
            // eslint-disable-next-line react/forbid-elements
            <a
                // eslint-disable-next-line
                href={absoluteUrl}
                data-tn={dataTn}
                tabIndex={inert ? -1 : undefined}
                className={classnames(className, { [styles.inert]: inert })}
                {...otherProps}
            >
                {children}
            </a>
        );
    } else {
        return (
            <span
                data-tn={dataTn}
                className={classnames(className, { [styles.inert]: inert })}
                {...otherProps}
            >
                {children}
            </span>
        );
    }
};

AbsoluteAnchorComponent.propTypes = {
    localeDomain: PropTypes.string.isRequired,
    children: PropTypes.node,
    dataTn: PropTypes.string,
    href: PropTypes.string,
    linkReference: PropTypes.string,
    linkable: PropTypes.bool,
    dispatch: PropTypes.func,
    inert: PropTypes.bool,
};

const mapStateToProps = state => {
    const { intlVariables } = state;

    return { localeDomain: intlVariables.localeDomain };
};

export const AbsoluteAnchor = connect(mapStateToProps)(AbsoluteAnchorComponent);
