import { useState, useEffect } from 'react';
import { localStorage } from 'dibs-browser-storage';
import { graphql, readInlineData } from 'react-relay/legacy';
import { getFilterValues } from './SbSharedRefineMenu/sbSharedRefineMenuHelpers';
import { shallowEqual, useSelector } from 'react-redux';

// constants
import { LOCAL_STORAGE_KEY } from '../constants/measurementUnits';

export function useMeasurementUnit({ user, itemSearch }) {
    const defaultMeasurementUnit = useSelector(state => state.measurementUnit, shallowEqual);
    const userData = readInlineData(
        graphql`
            fragment useMeasurementUnit_user on User @inline {
                preferences {
                    measurementUnit
                }
            }
        `,
        user
    );

    const itemSearchData = readInlineData(
        graphql`
            fragment useMeasurementUnit_itemSearch on ItemSearchQueryConnection @inline {
                appliedFilters {
                    name
                    values {
                        urlLabel
                    }
                }
            }
        `,
        itemSearch
    );

    const userValue = userData?.preferences?.measurementUnit || '';
    const appliedFilterValue = (
        getFilterValues(itemSearchData?.appliedFilters, 'measurement-unit')?.[0]?.urlLabel || ''
    ).toUpperCase();
    const [measurementUnit, setMeasurementUnit] = useState(
        /**
         * Same order, just without localStorage
         */
        appliedFilterValue || userValue || defaultMeasurementUnit
    );

    useEffect(() => {
        setMeasurementUnit(() => {
            const localStorageValue = localStorage.getItem(LOCAL_STORAGE_KEY);

            /**
             * If localstorage value is set to something different than the user value
             * dimensions will flash, this check will set the localstorage value to the user value
             * to prevent the dimension flashing on next refresh
             */
            if (userValue && userValue !== localStorageValue) {
                localStorage.setItem(LOCAL_STORAGE_KEY, userValue);
            }

            /**
             * Set the measurement unit with the following order of importance:
             *  1. if it is an applied filter
             *  2. to the users preference
             *  3. to what is stored in localStorage
             *  4. location default
             */
            const newMeasurementUnit =
                appliedFilterValue || userValue || localStorageValue || defaultMeasurementUnit;
            return newMeasurementUnit;
        });
    }, [userValue, appliedFilterValue, defaultMeasurementUnit]);

    return [measurementUnit];
}
