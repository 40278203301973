import { FC } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import Helmet from 'react-helmet';

import {
    ServerVarsContextConsumer,
    ServerVarsContextType,
} from '../../global/ServerVarsContext/ServerVarsContext';

import { SharedHeadMetaTags_metadata$data } from './__generated__/SharedHeadMetaTags_metadata.graphql';
import { SharedHeadMetaTags_items$data } from './__generated__/SharedHeadMetaTags_items.graphql';
import { handleLocaleUrl } from 'dibs-intl/exports/urls';
import { useIntl } from 'dibs-react-intl';
import { addTrailingSlash, addLeadingSlash } from '../../utils/uriUtils';

type Props = {
    metadata: SharedHeadMetaTags_metadata$data;
    items?: SharedHeadMetaTags_items$data;
    localeOrigin?: ServerVarsContextType['localeOrigin'];
    clientConf?: ServerVarsContextType['clientConf'];
    imageFallback?: string;
};

const MAX_IMAGE_COUNT = 3;

const SharedHeadMetaTagsComponent: FC<Props> = ({
    metadata,
    items,
    localeOrigin,
    clientConf,
    imageFallback,
}) => {
    const { title, description, canonical, disableIndexing } = metadata;
    const intl = useIntl();
    const canonicalUrl = addTrailingSlash(
        `${localeOrigin}${handleLocaleUrl(addLeadingSlash(canonical || ''), intl.locale)}`
    );

    const metaTagsArray = [
        {
            name: 'description',
            content: description || '',
        },
        {
            name: 'og:title',
            content: title || '',
        },
        {
            name: 'og:type',
            content: 'website',
        },
        {
            name: 'og:site_name',
            content: '1stDibs.com',
        },
        {
            name: 'fb:admins',
            content: (clientConf?.facebookAdmin as string) || '',
        },
        {
            name: 'fb:app_id',
            content: (clientConf?.facebookAppId as string) || '',
        },
        {
            name: 'fb:page_id',
            content: (clientConf?.facebookPageId as string) || '',
        },
        {
            name: 'twitter:card',
            content: 'summary',
        },
        {
            name: 'twitter:site',
            content: '@1stdibs',
        },
        {
            name: 'twitter:title',
            content: title || '',
        },
        {
            name: 'twitter:description',
            content: description || '',
        },
        {
            name: 'twitter:image',
            content: items?.[0]?.photos?.[0]?.versions?.[0]?.webPath || imageFallback || '',
        },
    ];

    // itemSearchQuery has indexation logic on canonical field because of that we need to do a check here
    if (canonical) {
        metaTagsArray.push({
            name: 'og:url',
            content: canonicalUrl,
        });
        metaTagsArray.push({
            name: 'twitter:url',
            content: canonicalUrl,
        });
    }
    if (items?.length) {
        items.slice(0, MAX_IMAGE_COUNT).map(item => {
            metaTagsArray.push({
                name: 'og:image',
                content: item?.photos?.[0]?.versions?.[0]?.webPath || '',
            });
        });
    } else if (imageFallback) {
        metaTagsArray.push({ name: 'og:image', content: imageFallback });
    }

    if (disableIndexing) {
        metaTagsArray.push({
            name: 'robots',
            content: 'noindex, follow',
        });
    }

    return <Helmet meta={metaTagsArray} />;
};

const SharedHeadMetaTagsServerVarsContextComponent: FC<Props> = props => {
    return (
        <ServerVarsContextConsumer>
            {({ clientConf, localeOrigin }) => (
                <SharedHeadMetaTagsComponent
                    {...props}
                    clientConf={clientConf}
                    localeOrigin={localeOrigin}
                />
            )}
        </ServerVarsContextConsumer>
    );
};

/*
 * We need items for this component. Since Querys created using dibs-graphql helper `createConnection`
 * has `edges` field which has a different name for every query we need to unmask this data in parent
 * component and pass items as prop.
 */
// eslint-disable-next-line
const fragment = {};

export const SharedHeadMetaTags = createFragmentContainer(
    SharedHeadMetaTagsServerVarsContextComponent,
    {
        metadata: graphql`
            fragment SharedHeadMetaTags_metadata on MetaData {
                title
                description
                canonical
                disableIndexing
            }
        `,
        items: graphql`
            fragment SharedHeadMetaTags_items on Item @relay(plural: true) {
                photos(limit: 1) {
                    versions {
                        webPath
                    }
                }
            }
        `,
    }
);
