/**
 * @generated SignedSource<<1384ca389adf2709713c914d914dcae9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbSharedRefineMenuFilterItemLocation_viewer$data = {
  readonly locations?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly count: number | null;
        readonly displayName: string | null;
        readonly linkReference: string | null;
        readonly urlLabel: string | null;
      } | null;
    } | null> | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuRadioSelectSearch_viewer">;
  readonly " $fragmentType": "SbSharedRefineMenuFilterItemLocation_viewer";
};
export type SbSharedRefineMenuFilterItemLocation_viewer$key = {
  readonly " $data"?: SbSharedRefineMenuFilterItemLocation_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuFilterItemLocation_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "isClient"
    },
    {
      "kind": "RootArgument",
      "name": "uriRef"
    },
    {
      "kind": "RootArgument",
      "name": "userCountryCode"
    },
    {
      "kind": "RootArgument",
      "name": "userZipCode"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbSharedRefineMenuFilterItemLocation_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuRadioSelectSearch_viewer"
    },
    {
      "condition": "isClient",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": "locations",
          "args": [
            {
              "kind": "Variable",
              "name": "countryCode",
              "variableName": "userCountryCode"
            },
            {
              "kind": "Literal",
              "name": "facetLimit",
              "value": 10
            },
            {
              "kind": "Literal",
              "name": "facetName",
              "value": "location"
            },
            {
              "kind": "Variable",
              "name": "uriRef",
              "variableName": "uriRef"
            },
            {
              "kind": "Variable",
              "name": "zipCode",
              "variableName": "userZipCode"
            }
          ],
          "concreteType": "ItemFacetsQueryConnection",
          "kind": "LinkedField",
          "name": "itemFacetSearch",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ItemFacetsQueryEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "FilterValueType",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "count",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "displayName",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "urlLabel",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "linkReference",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "208651edf2af2002bd2d0a72405f7d00";

export default node;
