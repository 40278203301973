import { defineMessages } from 'dibs-react-intl';

// components
import { SbSharedRefineMenuFilterMetal } from './SbSharedRefineMenuFilterMetal';
import { SbSharedRefineMenuFilterStyle } from './SbSharedRefineMenuFilterStyle';
import { SbSharedRefineMenuFilterArtSubject } from './SbSharedRefineMenuFilterArtSubject';
import { SbSharedRefineMenuFilterColor } from './SbSharedRefineMenuFilterColor';
import { SbSharedRefineMenuFilterStone } from './SbSharedRefineMenuFilterStone';
import { SbSharedRefineMenuFilterPeriod } from './SbSharedRefineMenuFilterPeriod';
import { SbSharedRefineMenuFilterRingSize } from './SbSharedRefineMenuFilterRingSize';
import { SbSharedRefineMenuFilterOrigin } from './SbSharedRefineMenuFilterOrigin';
import { SbSharedRefineMenuFilterCreator } from './SbSharedRefineMenuFilterCreator';
import { SbSharedRefineMenuFilterMaterial } from './SbSharedRefineMenuFilterMaterial';
import { SbSharedRefineMenuFilterItemType } from './SbSharedRefineMenuFilterItemType';
import { SbSharedRefineMenuFilterJewelryType } from './SbSharedRefineMenuFilterJewelryType';
import { SbSharedRefineMenuFilterAvailability } from './SbSharedRefineMenuFilterAvailability';
import { SbSharedRefineMenuFilterMatchingSets } from './SbSharedRefineMenuFilterMatchingSets';
import { SbSharedRefineMenuFilterGender } from './SbSharedRefineMenuFilterGender';
import { SbSharedRefineMenuFilterFraming } from './SbSharedRefineMenuFilterFraming';
import { SbSharedRefineMenuFilterArtOrientation } from './SbSharedRefineMenuFilterArtOrientation';
import { SbSharedRefineMenuFilterFramingOld } from './SbSharedRefineMenuFilterFramingOld';
import { SbSharedRefineMenuFilterMovement } from './SbSharedRefineMenuFilterMovement';

const titleOverrides = defineMessages({
    framing: {
        id: 'sb.SharedRefineMenuFilterFramingOptions.title',
        defaultMessage: 'Framing',
    },
});

/* eslint-disable camelcase */

export const sbSharedRefineMenuFiltersMap = {
    color: {
        component: SbSharedRefineMenuFilterColor,
    },
    creator: {
        component: SbSharedRefineMenuFilterCreator,
    },
    item_type: {
        component: SbSharedRefineMenuFilterItemType,
    },
    'jewelry-type': {
        component: SbSharedRefineMenuFilterJewelryType,
    },
    lead_time_bucket: {
        component: SbSharedRefineMenuFilterAvailability,
    },
    matchingSets: {
        component: SbSharedRefineMenuFilterMatchingSets,
    },
    material: {
        component: SbSharedRefineMenuFilterMaterial,
    },
    metal: {
        component: SbSharedRefineMenuFilterMetal,
    },
    origin: {
        component: SbSharedRefineMenuFilterOrigin,
    },
    period: {
        component: SbSharedRefineMenuFilterPeriod,
    },
    'ring-size': {
        component: SbSharedRefineMenuFilterRingSize,
    },
    stone: {
        component: SbSharedRefineMenuFilterStone,
    },
    style: {
        component: SbSharedRefineMenuFilterStyle,
    },
    'subject-matter': {
        component: SbSharedRefineMenuFilterArtSubject,
    },
    gender: {
        component: SbSharedRefineMenuFilterGender,
    },
    frameIncluded: {
        component: SbSharedRefineMenuFilterFramingOld,
        title: titleOverrides.framing,
        isBatchAppliedFilter: true,
    },
    framing: {
        component: SbSharedRefineMenuFilterFraming,
        title: titleOverrides.framing,
        isBatchAppliedFilter: true,
    },
    framingOptionAvailable: {
        component: null,
        isBatchAppliedFilter: true,
    },
    'art-shape': {
        component: SbSharedRefineMenuFilterArtOrientation,
    },
    movement: {
        component: SbSharedRefineMenuFilterMovement,
    },

    /**
     * Pass in empty objects to indicate that the filter can appear in applied filters, but it won't be
     * shown in the refine menu filter list.
     */
    refineSearch: {},
    technique: {},
    'shipping-free': {
        isBatchAppliedFilter: true,
    },
    'max-white-glove-price': {},
    'white-glove-shipping': {
        isBatchAppliedFilter: true,
    },
};
