import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { getFilterValues } from '../sbSharedRefineMenuHelpers';
import { LEAD_TIME_BUCKET_DEFAULT_URLLABEL } from '../sbSharedRefineMenuConstants';

// components
import { SbSharedRefineMenuRadioSelect } from './../SbSharedRefineMenuRadioSelect/SbSharedRefineMenuRadioSelect';
import { SbSharedRefineMenuExpandableItemList } from '../SbSharedRefineMenuExpandableItemList/SbSharedRefineMenuExpandableItemList';

export const SbSharedRefineMenuFilterAvailabilityComponent = props => {
    const { values, itemSearch, filterName, shouldUseExpandableList } = props;
    const { appliedFilters, displayUriRef } = itemSearch;

    const valuesToShowCopy = [...values];
    const appliedFilterValues = getFilterValues(appliedFilters, filterName);
    const defaultIndex = values.findIndex(
        value => value.urlLabel === LEAD_TIME_BUCKET_DEFAULT_URLLABEL
    );
    const defaultValue = defaultIndex > -1 ? valuesToShowCopy.splice(defaultIndex, 1)[0] : {};

    if (!shouldUseExpandableList) {
        return (
            <SbSharedRefineMenuRadioSelect
                canBeDismissed
                filterName={filterName}
                valuesToShow={valuesToShowCopy}
                displayUriRef={displayUriRef}
                totalCount={defaultValue.count}
                appliedFilterValues={appliedFilterValues}
            />
        );
    }

    return (
        <SbSharedRefineMenuExpandableItemList
            filterName={filterName}
            availableFilterValues={valuesToShowCopy}
        >
            {({ valuesToShow }) => (
                <SbSharedRefineMenuRadioSelect
                    canBeDismissed
                    filterName={filterName}
                    valuesToShow={valuesToShow}
                    displayUriRef={displayUriRef}
                    totalCount={defaultValue.count}
                    appliedFilterValues={appliedFilterValues}
                />
            )}
        </SbSharedRefineMenuExpandableItemList>
    );
};

SbSharedRefineMenuFilterAvailabilityComponent.propTypes = {
    values: PropTypes.array.isRequired,
    filterName: PropTypes.string.isRequired,
    itemSearch: PropTypes.object.isRequired,
    shouldUseExpandableList: PropTypes.bool,
};

SbSharedRefineMenuFilterAvailabilityComponent.defaultProps = {
    shouldUseExpandableList: true,
};

export const SbSharedRefineMenuFilterAvailability = createFragmentContainer(
    SbSharedRefineMenuFilterAvailabilityComponent,
    {
        itemSearch: graphql`
            fragment SbSharedRefineMenuFilterAvailability_itemSearch on ItemSearchQueryConnection {
                displayUriRef
                appliedFilters {
                    name
                    values {
                        linkReference
                        urlLabel
                        count
                    }
                }
            }
        `,
    }
);
