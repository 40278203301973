import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { getFilterValues } from '../sbSharedRefineMenuHelpers';

// components
import { SbSharedRefineMenuMultiSelect } from '../SbSharedRefineMenuMultiSelect/SbSharedRefineMenuMultiSelect';
import { SbSharedRefineMenuTypeaheadContainer } from '../SbSharedRefineMenuTypeahead/SbSharedRefineMenuTypeaheadContainer';

/* eslint-disable react/prop-types */

export const SbSharedRefineMenuMultiSelectSearchComponent = props => {
    const { viewer, values, itemSearch, filterName, collapsedItemCount } = props;
    const { appliedFilters } = itemSearch;

    const appliedFilterValues = getFilterValues(appliedFilters, filterName);

    return (
        <SbSharedRefineMenuTypeaheadContainer
            appliedFilterValues={appliedFilterValues}
            filterName={filterName}
            availableFilterValues={values}
            viewer={viewer}
            itemSearch={itemSearch}
            collapsedItemCount={collapsedItemCount}
        >
            {({ valuesToShow }) => (
                <SbSharedRefineMenuMultiSelect
                    filterName={filterName}
                    appliedFilterValues={appliedFilterValues}
                    valuesToShow={valuesToShow}
                />
            )}
        </SbSharedRefineMenuTypeaheadContainer>
    );
};

SbSharedRefineMenuMultiSelectSearchComponent.propTypes = {
    values: PropTypes.array.isRequired,
    itemSearch: PropTypes.object.isRequired,
    viewer: PropTypes.object.isRequired,
    filterName: PropTypes.string.isRequired,
};

export const SbSharedRefineMenuMultiSelectSearch = createFragmentContainer(
    SbSharedRefineMenuMultiSelectSearchComponent,
    {
        viewer: graphql`
            fragment SbSharedRefineMenuMultiSelectSearch_viewer on Viewer {
                ...SbSharedRefineMenuTypeaheadContainer_viewer
            }
        `,
        itemSearch: graphql`
            fragment SbSharedRefineMenuMultiSelectSearch_itemSearch on ItemSearchQueryConnection {
                ...SbSharedRefineMenuTypeaheadContainer_itemSearch
                appliedFilters {
                    name
                    localizedFilterName
                    canBeDismissed
                    values {
                        urlLabel
                        linkReference
                        count
                        displayName
                    }
                }
            }
        `,
    }
);
