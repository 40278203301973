import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useIntl, defineMessages } from 'dibs-react-intl';
import { updateUriRef } from '../../../actions/filterActions';
import { decode64 } from '../../../utils/decode';
import { CURRENCY } from '../../SbSharedRefineMenu/sbSharedRefineMenuConstants';

// components
import { SbSharedRefineMenuInputRadio } from '../SbSharedRefineMenuInput/SbSharedRefineMenuInputRadio';

// Used just for identifying the `all` value, should never be set in the filter store.
function getAllUrlLabel(filterName) {
    return `all ${filterName}`;
}

const { all } = defineMessages({
    all: {
        id: 'sb.SbMobileRefineMenu.radioSelect.all',
        defaultMessage: `{filterName, select,
            location {Anywhere}
            categoryL2 {All {allLabel}}
            categoryL3 {All {allLabel}}
            categoryL4 {All {allLabel}}
            price {Any price}
            priceTrade {Any price}
            other {All}
        }`,
    },
});

function SbSharedRefineMenuRadioSelectComponent(props) {
    const {
        appliedFilterValues,
        valuesToShow,
        filterName,
        canBeDismissed,
        allLabel,
        totalCount,
        currency,
        onUpdateUriRef,
        onChange = () => {},
        trackInputIndex = false,
        showAllOption,
    } = props;
    const intl = useIntl();
    const appliedUrlLabel = appliedFilterValues?.[0]?.urlLabel;
    const valuesToShowCopy = [...valuesToShow];

    if (canBeDismissed && (showAllOption || valuesToShow.length > 1)) {
        valuesToShowCopy.unshift({
            displayName: intl.formatMessage(all, { filterName, allLabel }),
            urlLabel: getAllUrlLabel(filterName),
            checked: !appliedUrlLabel,
            count: totalCount || 0,
            linkReference: appliedFilterValues?.[0]?.linkReference,
            currency,
        });
    }

    return (
        <>
            {valuesToShowCopy.map((item, index) => {
                const checked = item.checked || item.urlLabel === appliedUrlLabel;

                return (
                    <SbSharedRefineMenuInputRadio
                        key={item.urlLabel}
                        name={filterName}
                        value={item.urlLabel}
                        checked={checked}
                        linkReference={item.linkReference}
                        linkable={item.linkable}
                        onChange={(_value, event) => {
                            const { currency: itemCurrency, link, linkReference } = item || {};
                            let uriRef = decode64(linkReference) || link;

                            if (itemCurrency) {
                                uriRef = uriRef.replace(
                                    `{${CURRENCY}}`,
                                    itemCurrency.toLowerCase()
                                );
                            }
                            onChange();
                            onUpdateUriRef({
                                filterValue: item,
                                uriRef,
                                ga: trackInputIndex ? { label: `${filterName} ${index}` } : {},
                                event,
                            });
                        }}
                        text={item.displayName}
                        count={item.count}
                        isHidden={item.isHidden}
                    />
                );
            })}
        </>
    );
}

SbSharedRefineMenuRadioSelectComponent.propTypes = {
    appliedFilterValues: PropTypes.array.isRequired,
    filterName: PropTypes.string.isRequired,
    valuesToShow: PropTypes.any,
    onUpdateUriRef: PropTypes.func.isRequired,
    canBeDismissed: PropTypes.bool,
    allLabel: PropTypes.string,
    displayUriRef: PropTypes.string,
    totalCount: PropTypes.number,
    currency: PropTypes.string,
    onChange: PropTypes.func,
    trackInputIndex: PropTypes.bool,
    showAllOption: PropTypes.bool,
};

export const SbSharedRefineMenuRadioSelect = connect(null, (dispatch, { filterName }) => ({
    onUpdateUriRef: ({ filterValue, uriRef, ga, event }) =>
        dispatch(updateUriRef({ filterName, filterValue, uriRef, ga, event })),
}))(SbSharedRefineMenuRadioSelectComponent);
