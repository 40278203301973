import { FC, ReactNode } from 'react';
import { ComponentLoader } from './ComponentLoader/ComponentLoader';
import { TooltipProps } from 'dibs-elements/exports/Tooltip';

/**
 * TooltipWrapper Component Loader (dibs-elements/dist/Tooltip)
 */
const importFunc = (): Promise<ReactNode> => {
    return import(
        /* webpackChunkName: "Tooltip" */
        'dibs-elements/exports/Tooltip'
    ).then(moduleExport => moduleExport.Tooltip) as Promise<ReactNode>;
};

type Props = TooltipProps & { load: boolean };

export const TooltipLoader: FC<Props> = props => {
    return (
        <ComponentLoader
            {...props}
            load={props.load}
            importFunc={importFunc}
            useLoadingComponent={false}
        />
    );
};
