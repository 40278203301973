import { createFragmentContainer, graphql } from 'react-relay/legacy';

// components
import { SbSharedRefineMenuMultiSelectList } from '../SbSharedRefineMenuMultiSelect/SbSharedRefineMenuMultiSelectList';

export const SbSharedRefineMenuFilterGenderComponent = props => (
    <SbSharedRefineMenuMultiSelectList {...props} />
);

export const SbSharedRefineMenuFilterGender = createFragmentContainer(
    SbSharedRefineMenuFilterGenderComponent,
    {
        itemSearch: graphql`
            fragment SbSharedRefineMenuFilterGender_itemSearch on ItemSearchQueryConnection {
                ...SbSharedRefineMenuMultiSelectList_itemSearch
            }
        `,
    }
);
