import { createFragmentContainer, graphql } from 'react-relay/legacy';

// components
import { SbSharedRefineMenuMultiSelectSearch } from '../SbSharedRefineMenuMultiSelect/SbSharedRefineMenuMultiSelectSearch';

export const SbSharedRefineMenuFilterOriginComponent = props => (
    <SbSharedRefineMenuMultiSelectSearch {...props} />
);

export const SbSharedRefineMenuFilterOrigin = createFragmentContainer(
    SbSharedRefineMenuFilterOriginComponent,
    {
        viewer: graphql`
            fragment SbSharedRefineMenuFilterOrigin_viewer on Viewer {
                ...SbSharedRefineMenuMultiSelectSearch_viewer
            }
        `,
        itemSearch: graphql`
            fragment SbSharedRefineMenuFilterOrigin_itemSearch on ItemSearchQueryConnection {
                ...SbSharedRefineMenuMultiSelectSearch_itemSearch
            }
        `,
    }
);
