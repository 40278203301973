import { createFragmentContainer, graphql } from 'react-relay/legacy';

// components
import { SbSharedRefineMenuRadioSelectList } from '../SbSharedRefineMenuRadioSelect/SbSharedRefineMenuRadioSelectList';

export const SbSharedRefineMenuFilterJewelryTypeComponent = props => (
    <SbSharedRefineMenuRadioSelectList {...props} />
);

export const SbSharedRefineMenuFilterJewelryType = createFragmentContainer(
    SbSharedRefineMenuFilterJewelryTypeComponent,
    {
        itemSearch: graphql`
            fragment SbSharedRefineMenuFilterJewelryType_itemSearch on ItemSearchQueryConnection {
                ...SbSharedRefineMenuRadioSelectList_itemSearch
            }
        `,
    }
);
