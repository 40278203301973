import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { getFilterValues } from '../../SbSharedRefineMenu/sbSharedRefineMenuHelpers';
import { updateUriRef } from '../../../actions/filterActions';

// components
import { SbRespRefineMenuStoneCutSelectInput } from './SbRespRefineMenuStoneCutSelectInput';

export const SbRespRefineMenuStoneCutSelectComponent = props => {
    const { itemSearch, valuesToShow, filterName, onClick, stoneCutMap } = props;
    const { appliedFilters } = itemSearch;

    const appliedFilterValues = getFilterValues(appliedFilters, filterName);

    return (
        <div>
            {valuesToShow.map((item, index) => {
                const isChecked = appliedFilterValues.some(
                    appliedValue => appliedValue.urlLabel === item.urlLabel
                );
                return (
                    <SbRespRefineMenuStoneCutSelectInput
                        count={item.count}
                        checked={isChecked}
                        linkReference={item.linkReference}
                        linkable={item.linkable}
                        onClick={event => {
                            onClick(item, isChecked, event);
                        }}
                        text={item.displayName}
                        stoneCut={item.urlLabel}
                        key={`${item.urlLabel}-${index}`}
                        stoneCutMap={stoneCutMap}
                        isHidden={item.isHidden}
                    />
                );
            })}
        </div>
    );
};

SbRespRefineMenuStoneCutSelectComponent.propTypes = {
    onClick: PropTypes.func.isRequired,
    filterName: PropTypes.string.isRequired,
    valuesToShow: PropTypes.array.isRequired,
    itemSearch: PropTypes.object.isRequired,
    stoneCutMap: PropTypes.object,
};

function mapDispatchToProps(dispatch, { filterName }) {
    return {
        onClick(filterValue, isDeselected, event) {
            dispatch(updateUriRef({ filterName, filterValue, isDeselected, event }));
        },
    };
}

export const SbRespRefineMenuStoneCutSelect = createFragmentContainer(
    connect(null, mapDispatchToProps)(SbRespRefineMenuStoneCutSelectComponent),
    {
        itemSearch: graphql`
            fragment SbRespRefineMenuStoneCutSelect_itemSearch on ItemSearchQueryConnection {
                appliedFilters {
                    name
                    values {
                        urlLabel
                    }
                }
            }
        `,
    }
);
