/**
 * @generated SignedSource<<ccc9521a109fb27c55919b1fa290bba2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SharedHeadTitleTag_metadata$data = {
  readonly title: string | null;
  readonly topQueries: ReadonlyArray<string | null> | null;
  readonly " $fragmentType": "SharedHeadTitleTag_metadata";
};
export type SharedHeadTitleTag_metadata$key = {
  readonly " $data"?: SharedHeadTitleTag_metadata$data;
  readonly " $fragmentSpreads": FragmentRefs<"SharedHeadTitleTag_metadata">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SharedHeadTitleTag_metadata",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "topQueries",
      "storageKey": null
    }
  ],
  "type": "MetaData",
  "abstractKey": null
};

(node as any).hash = "45d639e54c511eeee4b66ad912538d3b";

export default node;
