import { FC } from 'react';
import { SbSharedRefineMenuFramingInput_itemSearch$key } from './__generated__/SbSharedRefineMenuFramingInput_itemSearch.graphql';

import { graphql, useFragment } from 'react-relay';
import { useIntl, MessageDescriptor } from 'dibs-react-intl';
import { useDispatch } from 'react-redux';
import { updateUriRef } from '../../../actions/filterActions';
import { getFilterValues, hasFilterApplied } from '../sbSharedRefineMenuHelpers';

import { SbSharedRefineMenuInputCheckbox } from '../../SbSharedRefineMenu/SbSharedRefineMenuInput/SbSharedRefineMenuInputCheckbox';

type Props = {
    itemSearch: SbSharedRefineMenuFramingInput_itemSearch$key;
    filterName: string;
    title: MessageDescriptor;
};

export const SbSharedRefineMenuFramingInput: FC<Props> = props => {
    const { itemSearch: itemSearchRef, filterName, title } = props;
    const intl = useIntl();
    const dispatch = useDispatch();

    const itemSearch = useFragment(
        graphql`
            fragment SbSharedRefineMenuFramingInput_itemSearch on ItemSearchQueryConnection {
                appliedFilters {
                    name
                    values {
                        linkReference
                    }
                }
                filters {
                    name
                    values {
                        linkReference
                        linkable
                        urlLabel
                        count
                    }
                }
            }
        `,
        itemSearchRef
    );

    const filters = itemSearch?.filters || [];
    const appliedFilters = itemSearch?.appliedFilters || [];

    const framingFilterValues = getFilterValues(filters, filterName);
    const isFilterApplied = hasFilterApplied(appliedFilters, [filterName]);

    return (
        <>
            {framingFilterValues.map(filter => (
                <SbSharedRefineMenuInputCheckbox
                    key={filter.urlLabel}
                    value={filter.urlLabel || ''}
                    linkReference={filter.linkReference}
                    linkable={filter.linkable}
                    checked={isFilterApplied}
                    onChange={(checked, event) => {
                        dispatch(
                            updateUriRef({
                                filterName,
                                filterValue: filter,
                                isDeselected: !checked,
                                uriRef: null,
                                event,
                                ga: undefined,
                            })
                        );
                    }}
                    text={intl.formatMessage(title)}
                    count={filter?.count}
                />
            ))}
        </>
    );
};
