import { createFragmentContainer, graphql } from 'react-relay/legacy';

// components
import { SbSharedRefineMenuMultiSelectSearch } from '../SbSharedRefineMenuMultiSelect/SbSharedRefineMenuMultiSelectSearch';

export const SbSharedRefineMenuFilterMetalComponent = props => (
    <SbSharedRefineMenuMultiSelectSearch {...props} />
);

export const SbSharedRefineMenuFilterMetal = createFragmentContainer(
    SbSharedRefineMenuFilterMetalComponent,
    {
        viewer: graphql`
            fragment SbSharedRefineMenuFilterMetal_viewer on Viewer {
                ...SbSharedRefineMenuMultiSelectSearch_viewer
            }
        `,
        itemSearch: graphql`
            fragment SbSharedRefineMenuFilterMetal_itemSearch on ItemSearchQueryConnection {
                ...SbSharedRefineMenuMultiSelectSearch_itemSearch
            }
        `,
    }
);
