import PropTypes from 'prop-types';
import slugify from 'dibs-slugify/exports/slugify';

// styles
import styles from './SbSharedRefineMenuDimensionFacet.scss';

export const SbSharedRefineMenuDimensionFacet = props => {
    const { title, children, id } = props;

    return (
        <div className={styles.container} data-tn={`input-range-${slugify(title)}`}>
            <div id={id} className={styles.title}>
                {title}
            </div>
            {children}
        </div>
    );
};

SbSharedRefineMenuDimensionFacet.propTypes = {
    children: PropTypes.element,
    title: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
};
