import { graphql, commitMutation } from 'react-relay/legacy';

const mutation = graphql`
    mutation SharedEditMeasurementUnitPreferenceMutation($input: EditPersonalProfileInput!) {
        editPersonalProfile(input: $input) {
            user {
                preferences {
                    measurementUnit
                }
            }
        }
    }
`;

function commit(environment, { userServiceId, measurementUnit, onCompleted, onError }) {
    commitMutation(environment, {
        mutation,
        variables: {
            input: {
                userServiceId,
                measurementUnit,
            },
        },
        onCompleted,
        onError,
    });
}

export const SharedEditMeasurementUnitPreference = {
    commit,
};
