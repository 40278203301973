/**
 * @generated SignedSource<<cf75ed836736978f66686177643e6a7b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbSharedRefineMenuRingSizeUnitSelector_itemSearch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useRingMeasurementType_itemSearch">;
  readonly " $fragmentType": "SbSharedRefineMenuRingSizeUnitSelector_itemSearch";
};
export type SbSharedRefineMenuRingSizeUnitSelector_itemSearch$key = {
  readonly " $data"?: SbSharedRefineMenuRingSizeUnitSelector_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuRingSizeUnitSelector_itemSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbSharedRefineMenuRingSizeUnitSelector_itemSearch",
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "useRingMeasurementType_itemSearch",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SearchBrowseParsedParam",
          "kind": "LinkedField",
          "name": "appliedFilters",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "SearchBrowseParsedParamValue",
              "kind": "LinkedField",
              "name": "values",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "urlLabel",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "33a27257dad99f62db884eb2d8ffa9c7";

export default node;
