import PropTypes from 'prop-types';
import { useFragment, graphql } from 'react-relay';

import { SbSharedRefineMenuMultiSelectList } from '../SbSharedRefineMenuMultiSelect/SbSharedRefineMenuMultiSelectList';

export const SbSharedRefineMenuFilterMovement = props => {
    const itemSearch = useFragment(
        graphql`
            fragment SbSharedRefineMenuFilterMovement_itemSearch on ItemSearchQueryConnection {
                ...SbSharedRefineMenuMultiSelectList_itemSearch
            }
        `,
        props.itemSearch
    );
    return <SbSharedRefineMenuMultiSelectList {...props} itemSearch={itemSearch} />;
};

SbSharedRefineMenuFilterMovement.propTypes = {
    itemSearch: PropTypes.object.isRequired,
};
