import { useState, useEffect } from 'react';
import { graphql, readInlineData } from 'react-relay/legacy';
import { getFilterValues } from '../../SbSharedRefineMenu/sbSharedRefineMenuHelpers';
import {
    RING_MEASUREMENT_TYPE_PARAM,
    CURRENCY_USD,
    RING_SIZE_US,
    RING_SIZE_MM,
} from '../../../constants/ringSize';

export function useRingMeasurementType({ currency, itemSearch: itemSearchRef }) {
    const defaultRingMeasurementType = currency === CURRENCY_USD ? RING_SIZE_US : RING_SIZE_MM;

    const itemSearch = readInlineData(
        graphql`
            fragment useRingMeasurementType_itemSearch on ItemSearchQueryConnection @inline {
                appliedFilters {
                    name
                    values {
                        urlLabel
                    }
                }
            }
        `,
        itemSearchRef
    );

    const filterValues = getFilterValues(itemSearch.appliedFilters, RING_MEASUREMENT_TYPE_PARAM);
    const appliedFilterValue = filterValues?.[0]?.urlLabel.toUpperCase() || '';

    const [ringMeasurementType, setRingSizeUnit] = useState(
        appliedFilterValue || defaultRingMeasurementType
    );

    useEffect(() => {
        setRingSizeUnit(appliedFilterValue || defaultRingMeasurementType);
    }, [appliedFilterValue, defaultRingMeasurementType]);

    return [ringMeasurementType];
}
