import { createFragmentContainer, graphql } from 'react-relay/legacy';

// components
import { SbSharedRefineMenuColorSelect } from '../SbSharedRefineMenuColorSelect/SbSharedRefineMenuColorSelect';

export const SbSharedRefineMenuFilterColorComponent = props => (
    <SbSharedRefineMenuColorSelect {...props} />
);

export const SbSharedRefineMenuFilterColor = createFragmentContainer(
    SbSharedRefineMenuFilterColorComponent,
    {
        itemSearch: graphql`
            fragment SbSharedRefineMenuFilterColor_itemSearch on ItemSearchQueryConnection {
                ...SbSharedRefineMenuColorSelect_itemSearch
            }
        `,
    }
);
